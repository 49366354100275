import {get} from "@/services/api";
import {randomPastelColor} from '@/utils/colors';

const data_set_structure = (char = 'horizontalBar') => ([
    {
      label: 'Credits',
      data: [],
      borderColor: char === 'horizontalBar' ? "#17c671" : [],
      backgroundColor: char === 'horizontalBar' ? "#17c671" : [],
    },
    {
      label: 'Price',
      data: [],
      borderColor: char === 'horizontalBar' ? "#ffb400" : [],
      backgroundColor: char === 'horizontalBar' ? "#ffb400" : [],
    },
    {
      label: 'Average',
      data: [],
      borderColor: char === 'horizontalBar' ? "#FF4169" : [],
      backgroundColor: char === 'horizontalBar' ? "#FF4169" : [],
    },
  ])

export default {
  namespaced: true,
  state: {
    users: [],
    params: {
      time: 'month',
      from_previous: null,
      to_previous: null,
      from_current: null,
      to_current: null,
      compare: 'week',
      market: false,
      page: 'revenue',
      graphic: 'horizontalBar',
    },
    users_selected: [],
    revenue_graphic: {
      type: 'horizontalBar',
      labels: [],
      original_labels: [],
      data: [{dataset: [], user_id: null}],
    },
    revenue_total: {
      movements: 0,
      credits: 0,
      price: 0,
      average: 0
    },
  },
  mutations: {
    setUsers: (state, payload) => {
      state.users = payload
    },
    setParams: (state, payload) => {
      state.params = payload
    },
    setRevenueTotal: (state, payload) => {
      state.revenue_total = payload;
    },
    setGraphicType: (state, payload) => {
      state.revenue_graphic.type = payload;
    },
    setUserSelected: (state, payload) => {
      state.users_selected = payload;
    },
  },
  getters: {
    revenue_graphic: state => {
      return processData(state.users, state.users_selected, state.revenue_graphic.type)
    },
    revenue_graphic_type: state => {
      return state.revenue_graphic.type
    }
  },
  actions: {
    fetchRevenueSummary({commit, state}) {
      get(`reports/credit-revenue-summary?time=${state.params.time}&compare=${state.params.compare}&ids=null&from=${state.params.from_previous}&to=${state.params.to_current}`,
        null, true).then(({data}) => {
        commit("setUsers", data.users)
        commit("setParams", {
          ...state.params,
          from_previous: data.params.from_previous,
          to_previous: data.params.to_previous,
          from_current: data.params.from_current,
          to_current: data.params.to_current
        })
        const total = calculateTotal(data.users)
        commit("setRevenueTotal", total)
      })
    },
  }
}

export function processData(users, ids = [], char_type = 'horizontalBar') {
  const labels = [];
  const data_sets = data_set_structure(char_type)
  const setData = (item) => {
    const color = randomPastelColor()
    item.colors = {
      backgroundColor: color, borderColor: color, pointBackgroundColor: color, pointHoverBackgroundColor: color,
    }
    data_sets[0].data.push(Number((item.credits).toFixed(0)))
    data_sets[1].data.push(Number((item.price).toFixed(0)))
    data_sets[2].data.push(Number(item.average).toFixed(0))
    if (char_type === 'pie') {
      data_sets[0].backgroundColor.push(item.colors.backgroundColor)
      data_sets[1].backgroundColor.push(item.colors.backgroundColor)
      data_sets[2].backgroundColor.push(item.colors.backgroundColor)
    }
    labels.push(item.username)
  }

  users.forEach(user => {
    if (!ids.length || ids.includes(user.user_id))
      setData(user)
  })
  return {data: data_sets, labels}
}

function calculateTotal(users) {
  const revenue_total = {
    movements: 0,
    credits: 0,
    price: 0,
    average: 0
  }
  revenue_total.average = users.reduce((prev, curr) => prev + Number(curr.average), 0)
  revenue_total.average = revenue_total.average / users.length
  revenue_total.credits = users.reduce((prev, curr) => prev + Number(curr.credits), 0)
  revenue_total.price = users.reduce((prev, curr) => prev + Number(curr.price), 0)
  revenue_total.movements = users.reduce((prev, curr) => prev + Number(curr.total_rows), 0)
  return revenue_total
}

import {onRequestPrice} from "@/constants";

export const comboImages = {
  '1': require('@/assets/images/stages/1.svg'),
  '2': require('@/assets/images/stages/2.svg'),
  '2.1': require('@/assets/images/stages/2P.svg'),
  '3': require('@/assets/images/stages/3.svg'),
  '4': require('@/assets/images/stages/4.svg')
};

export const processCombos = (comboObject, ecuTunning, dsgTunning) => {
  if (comboObject) {
    comboObject.combos = comboObject.combos.map((combo) => {
      combo.id = `${combo.ecu}-${combo.dsg}`;
      combo.type = 'ECU + DSG';
      combo.dsgObject = dsgTunning.find(el => el.id == combo.dsg);
      combo.ecuObject = ecuTunning.find(el => el.id == combo.ecu);
      if (combo.dsgObject && combo.ecuObject) {
        combo.left = comboImages[combo.ecu];
        combo.right = comboImages[combo.dsg];
        combo.price = calculatePriceCombo(combo.price, combo.ecuObject.price, combo.dsgObject.price)
        combo.discount = calculateDiscount(parseInt(combo.price), parseInt(combo.ecuObject.price) + parseInt(combo.dsgObject.price));
        return combo;
      }
      return false;
    })
      .filter(el => el !== false);
    return comboObject;
  } else {
    return {combos: []};
  }
};

export const getComboImages = (combos) => {
  if (combos.length) {
    combos = combos.map((combo) => {
      combo.id = `${combo.ecu}-${combo.dsg}`;
      combo.left = comboImages[combo.ecu];
      combo.right = comboImages[combo.dsg];
      return combo;
    });
  }
  return combos;
};

export const searchRelatedDatas = (key, sofwareTunning, dsgTunning) => {
  let ecu = sofwareTunning.find(el => el.nameCombo.includes(key));
  let dsg = dsgTunning.find(el => el.nameCombo.includes(key));
  if (ecu && dsg) {
    return {
      ecu,
      dsg
    };
  }
  return false;
};

export const calculateDiscount = (comboPrice, totalPriceCombo) => {
  return Math.round(100 - (comboPrice / totalPriceCombo) * 100);
};

export const calculatePriceCombo = (comboPrice, ecuPrice, dsgPrice) => {
  let priceTem = comboPrice && !isNaN(comboPrice) ? comboPrice : onRequestPrice;
  const priceEcu = ecuPrice && !isNaN(ecuPrice) ? ecuPrice : onRequestPrice;
  const priceDsg = dsgPrice && !isNaN(dsgPrice) ? dsgPrice : onRequestPrice;
  return (priceTem > (Number(priceEcu) + Number(priceDsg))) ? (Number(priceEcu) + Number(priceDsg)) : priceTem;
};

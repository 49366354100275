export default {
  namespaced: true,
  state: {
    orderId: null,
    dealerId: null,
    email: null,
    amount: 0,
    fee: 0,
    description: null,
    items: null,
    payment_method_types: [],
    origin: null,
    isEuropa: false,
    amount_original: 0,
    percentageFee: 0,
    countrySelectedCurrency: null,
    paymentSelected:null
  },
  mutations: {
    setPaymentSelected(state, value){
      state.paymentSelected = value
    },

    setCountrySelectedCurrency(state, value){
      state.countrySelectedCurrency = value
    },
    setAmountOriginal(state, value) {
      state.amount_original = value
    },
    setPaymentMethodTypes(state, value) {
      state.payment_method_types = value
    },
    setIsEuropa(state, value) {
      state.isEuropa = value
    },

    setCompleteDataPayment(state, value) {
      state.orderId = value.orderId
      state.dealerId = value.dealerId
      state.email = value.email
      state.amount = value.amount
      state.fee = value.fee
      state.amount_original = value.amount_original
      state.description = value.description
      state.items = value.items
      state.payment_method_types = value.payment_method_types
      state.origin = value.origin
      state.percentageFee = value.percentageFee
    }
  },
  getters: {
    paymentSelectedStatus:(state)=> {
      return state.paymentSelected
    },

    getCountrySelectedCurrency: (state) => {
      return state.countrySelectedCurrency
    },

    recalculatePrice: (state) => {
      if (state.payment_method_types.includes('card') && state.isEuropa) {
        state.amount = state.amount_original * 1.014
        state.percentageFee = 1.4
      } else if (state.payment_method_types.includes('card') && !state.isEuropa) {
        state.amount = state.amount_original * 1.029
        state.percentageFee = 2.9
      } else if (['UAE', 'Australia', 'Canada', 'Japan', 'Singapore', 'UK', 'USA'].includes(state.payment_method_types[0])) {
        state.amount = state.amount_original * 1.02
        state.percentageFee = 2
      } else if (['WorldWide'].includes(state.payment_method_types[0])) {
        state.amount = state.amount_original * 1.01
        state.percentageFee = 1
      } else if (['Eurozone','ideal'].includes(state.payment_method_types[0])) {
        state.amount = state.amount_original
        state.percentageFee = 0
      }
      return {amount: state.amount, percentageFee: state.percentageFee, amountOriginal: state.amount_original}
    },
  },
  actions: {
    calculate({getters}) {
      getters.recalculatePrice
    }
  }
}

<template>
  <span v-if="currency" :title="currency.name">
    <img v-if="(!noShowLogo && !symbolToEnd) && symbolVisibility" :src="getIcon()" class="symbol"
         :alt="currencyDetails.code"
         :class="{'ligth': ligth}">
    <span>{{ showLinesZero && amount === 0 ? '-' : price }}</span>
    <img v-if="(!noShowLogo && symbolToEnd) && symbolVisibility" :src="getIcon()" class="symbol"
         :alt="currencyDetails.code"
         :class="{'ligth': ligth}">
  </span>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {UserRole} from '@/utils/auth.roles';

export default {
  name: 'CreditsView',
  props: {
    amount: {
      required: true,
      type: Number,
    },
    currencyDetails: {
      default: false,
      type: Object
    },
    ligth: {
      default: false,
      type: Boolean
    },
    forceCurrrency: {
      default: false,
      type: Boolean
    },
    noShowLogo: {
      default: false,
      type: Boolean
    },
    showLinesZero: {
      default: false,
      type: Boolean
    },
    country_id: {
      default: null,
      type: Number
    },
    symbolToEnd: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapState('geo', ['userCurrency', 'tvsCurrency', 'showInLocal']),
    ...mapGetters('geo', ['getCurrencyByCountry']),
    currency: function () {
      if (!this.currencyDetails && !this.country_id) {
        if (this.showInLocal) {
          return this.userCurrency;
        } else {
          return {...this.tvsCurrency, locale: this.userCurrency.locale};
        }
      }
      if (this.country_id && this.showInLocal) {
        return this.getCurrencyByCountry(this.country_id)
      }
      // if (this.$userCan(UserRole.Admin) && !this.forceCurrrency) {
      //   return {...this.tvsCurrency, locale: this.userCurrency.locale};
      // }
      if (!this.showInLocal) {
        return {...this.tvsCurrency, locale: this.userCurrency.locale};
      }
      return {...this.currencyDetails, locale: this.userCurrency.locale};
    },
    price: function () {
      let amount = this.amount * (this.currency.price);
      let formater = new Intl.NumberFormat(this.currency.locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return formater.format(amount);
    },
    symbolVisibility() {
      if (this.showLinesZero) {
        return this.amount != 0
      }
      return true
    }
  },
  methods: {
    getIcon() {
      if (this.currency.code) {
        const code = this.currency.code.toLowerCase();
        try {
          return require(`@/assets/images/credits/${code}.svg`);
        } catch (e) {
          return require(`@/assets/images/credits/default.svg`);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
img.ligth {
  filter: invert(50%) saturate(0) brightness(160%);
}
</style>

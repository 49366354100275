<template>

  <b-navbar-nav class="flex-row menu_item_header" ref="navPanel">
    <li class="nav-item border-left border-right dropdown notifications" title="Software Notification" v-if="!$store.state.user.subUser">
      <a v-b-toggle.n_software class="nav-link nav-link-icon text-center">
        <div class="nav-link-icon__wrapper">
          <i class="material-icons bell" :class="{'btn--shockwave bell':software.length>0}">receipt</i>
          <b-badge v-if="software.length>0" pill variant="danger" class="btn--shockwave">{{ software.length }}</b-badge>
        </div>
      </a>
      <b-collapse id="n_software" ref="n_software" class="dropdown-menu dropdown-menu-small dropdown-menu-max"
                  v-model="statusCollapse.n_software">
        <div v-for="(item, index) in software" :key="index" style="position: relative">
          <!-- close notification -->
          <div v-if="item.id != null" style="position: absolute; right: 10px; top: 5px; font-size: 12px">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="alert"
              data-placement="top"
              data-toggle="tooltip"
              style="height: auto !important"
              title="Dismiss this notification"
              type="button"
              @click="closeNotification(index, item.ids, software)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <b-dropdown-item v-if="software.length" v-b-toggle.notifications
                           @click.native="viewNotification(index, item.ids, software, 'software')">
            <!-- notification icon -->
            <div class="notification__icon-wrapper">
              <div class="notification__icon">
                <i class="material-icons">receipt_long</i>
              </div>
            </div>
            <!-- notification content -->
            <div class="notification__content" style="width: 100%">
              <div><span class="notification__category">{{ item.title }}</span></div>
              <div style="display: flex; justify-content: space-between;">
                <p><strong>{{ (item.text.length > 130) ? item.text.substring(0, 130) + '...' : item.text }}</strong></p>
                <p>{{ item.time | moment('dddd hh:mm') }}</p>
              </div>
            </div>
          </b-dropdown-item>
        </div>
        <b-dropdown-item v-if="software.length === 0">
          <p>You have no new notifications!</p>
        </b-dropdown-item>
        <b-dropdown-item class="notification__all text-center">
          <div style="display: flex; width: 100%">
            <div v-if="viewOldNotification" style="display: flex; justify-content: center; width: 100%"
                 @click="clearNotifications('software', software)">
              <span v-if="software.length">Clear all Notifications</span>
            </div>
            <div v-if="!viewOldNotification" style="width: 100%">
              <span>View old(read) Notifications</span>
            </div>
          </div>
        </b-dropdown-item>
      </b-collapse>
    </li>
    <li class="nav-item border-left border-right dropdown notifications item_mobile" title="Support Notification"
        v-if="!$store.state.user.subUser">
      <a v-b-toggle.n_chats class="nav-link nav-link-icon text-center">
        <div class="nav-link-icon__wrapper">
          <i class="material-icons bell" :class="{'btn--shockwave bell':chats.length>0}">support_agent</i>
          <b-badge v-if="chats.length>0" pill variant="danger" class="btn--shockwave">{{ chats.length }}</b-badge>
        </div>
      </a>
      <b-collapse id="n_chats" ref="n_chats" class="dropdown-menu dropdown-menu-small dropdown-menu-max"
                  v-model="statusCollapse.n_chats">
        <div v-for="(item, index) in chats" :key="index" style="position: relative">
          <!-- close notification -->
          <div v-if="item.id != null" style="position: absolute; right: 10px; top: 5px; font-size: 12px" to="">
          </div>

          <b-dropdown-item v-if="chats.length" v-b-toggle.chats
                           @click.native="viewNotification(index, item.ids, chats, 'chats')">
            <!-- notification icon -->
            <div class="notification__icon-wrapper">
              <div class="notification__icon">
                <i class="material-icons">message</i>
              </div>
            </div>
            <!-- notification content -->
            <div class="notification__content" style="width: 100%">
              <div><span class="notification__category">{{ item.title }}</span></div>
              <div style="display: flex; justify-content: space-between;">
                <p><strong>{{ (item.text.length > 130) ? item.text.substring(0, 130) + '...' : item.text }}</strong></p>
                <p>{{ item.time | moment('dddd hh:mm') }}</p>
              </div>
            </div>
          </b-dropdown-item>
        </div>
        <b-dropdown-item v-if="chats.length === 0" href="#">
          <p>You have no new notifications!</p>
        </b-dropdown-item>
        <b-dropdown-item class="notification__all text-center">
          <div style="display: flex; width: 100%">
            <div v-if="viewOldNotification" style="display: flex; justify-content: center; width: 100%"
                 @click="clearNotifications('chats', software)">
              <span v-if="chats.length">Clear all Notifications</span>
            </div>
            <div v-if="!viewOldNotification" style="width: 100%">
              <span>View old(read) Notifications</span>
            </div>
          </div>
        </b-dropdown-item>
      </b-collapse>
    </li>
    <li class="nav-item border-left border-right dropdown notifications" title="Credits Notification"
        v-if="!$store.state.user.subUser">
      <a v-b-toggle.n_credits class="nav-link nav-link-icon text-center">
        <div class="nav-link-icon__wrapper">
          <i class="material-icons bell" :class="{'btn--shockwave bell':credits.length>0}">euro</i>
          <b-badge v-if="credits.length>0" pill variant="danger" class="btn--shockwave">{{ credits.length }}</b-badge>
        </div>
      </a>
      <b-collapse id="n_credits" ref="n_credits" class="dropdown-menu dropdown-menu-small dropdown-menu-max"
                  v-model="statusCollapse.n_credits">
        <div v-for="(item, index) in credits" :key="index" style="position: relative">
          <!-- close notification -->
          <div v-if="item.id != null" style="position: absolute; right: 10px; top: 5px; font-size: 12px">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="alert"
              data-placement="top"
              data-toggle="tooltip"
              style="height: auto !important"
              title="Dismiss this notification"
              type="button"
              @click="closeNotification(index, item.ids, credits)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <b-dropdown-item v-if="credits.length" v-b-toggle.chats
                           @click.native="viewNotification(index, item.ids, credits, 'credits')">
            <!-- notification icon -->
            <div class="notification__icon-wrapper">
              <div class="notification__icon">
                <i class="material-icons">credit_card</i>
              </div>
            </div>
            <!-- notification content -->
            <div class="notification__content" style="width: 100%">
              <div><span class="notification__category">{{ item.title }}</span></div>
              <div style="display: flex; justify-content: space-between;">
                <p><strong>{{ (item.text.length > 130) ? item.text.substring(0, 130) + '...' : item.text }}</strong></p>
                <p>{{ item.time | moment('dddd hh:mm') }}</p>
              </div>
            </div>
          </b-dropdown-item>
        </div>
        <b-dropdown-item v-if="credits.length === 0" href="#">
          <p>You have no new notifications!</p>
        </b-dropdown-item>
        <b-dropdown-item class="notification__all text-center">
          <div style="display: flex; width: 100%">
            <div v-if="viewOldNotification" style="display: flex; justify-content: center; width: 100%"
                 @click="clearNotifications('credits', credits)">
              <span v-if="credits.length">Clear all Notifications</span>
            </div>
            <div v-if="!viewOldNotification" style="width: 100%">
              <span>View old(read) Notifications</span>
            </div>
          </div>

        </b-dropdown-item>
      </b-collapse>
    </li>
    <li class="nav-item border-left border-right dropdown notifications" title="General Notification"
        v-if="!$store.state.user.subUser">
      <a v-b-toggle.n_general class="nav-link nav-link-icon text-center">
        <div class="nav-link-icon__wrapper">
          <i class="material-icons bell" :class="{'btn--shockwave bell':general.length>0}">notifications_active</i>
          <b-badge v-if="general.length>0" pill variant="danger" class="btn--shockwave">{{ general.length }}</b-badge>
        </div>
      </a>
      <b-collapse id="n_general" ref="n_general" class="dropdown-menu dropdown-menu-small dropdown-menu-max"
                  v-model="statusCollapse.n_general">
        <div v-for="(item, index) in general" :key="index" style="position: relative">
          <!-- close notification -->
          <div v-if="item.id != null" style="position: absolute; right: 10px; top: 5px; font-size: 12px" to="">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="alert"
              data-placement="top"
              data-toggle="tooltip"
              style="height: auto !important"
              title="Dismiss this notification"
              type="button"
              @click="closeNotification(index, item.ids, general)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <b-dropdown-item v-if="general.length" v-b-toggle.n_general
                           @click.native="viewNotification(index, item.ids, general, 'general')">
            <!-- notification icon -->
            <div class="notification__icon-wrapper">
              <div class="notification__icon">
                <i class="material-icons">notifications</i>
              </div>
            </div>
            <!-- notification content -->
            <div class="notification__content" style="width: 100%">
              <div><span class="notification__category">{{ item.title }}</span></div>
              <div style="display: flex; justify-content: space-between;">
                <p><strong>{{ (item.text.length > 130) ? item.text.substring(0, 130) + '...' : item.text }}</strong></p>
                <p>{{ item.time | moment('dddd hh:mm') }}</p>
              </div>
            </div>
          </b-dropdown-item>
        </div>
        <b-dropdown-item v-if="general.length === 0" href="#">
          <p>You have no new notifications!</p>
        </b-dropdown-item>
        <b-dropdown-item class="notification__all text-center">
          <div style="display: flex; width: 100%">
            <div v-if="viewOldNotification" style="display: flex; justify-content: center; width: 100%"
                 @click="clearNotifications('general', general)">
              <span v-if="general.length">Clear all Notifications</span>
            </div>
            <div v-if="!viewOldNotification" style="width: 100%">
              <span>View old(read) Notifications</span>
            </div>
          </div>
        </b-dropdown-item>
      </b-collapse>
    </li>
    <!--    END NOTIFICATION COMPANY-->
    <li class="nav-item dropdown">
      <a v-b-toggle.user-actions class="nav-link dropdown-toggle text-nowrap px-3">
        <img alt="User Avatar" class="user-avatar rounded-circle mr-2" src="@/assets/images/no-photo.svg"/>
        <span class="d-none d-md-inline-block">{{ username }}</span>
      </a>
      <b-collapse id="user-actions" ref="useractions" class="dropdown-menu dropdown-menu-small dropdown-menu-max"
                  v-model="statusCollapse.useractions">
        <div class="dropdown-item text-danger" role="button" @click="logout">
          <i class="material-icons text-danger">&#xE879;</i>
          <span>Logout</span>
        </div>
      </b-collapse>
    </li>
  </b-navbar-nav>


</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import {getDataStorage} from '@/helpers/localStorage';
import {get, post} from '@/services/api';
import {allNotifications, clearNotifications, readNotifications,} from '@/services/endpoint.json';
import {isArray} from "@/utils";
import {encrypt} from "@/helpers/encrypt";
import {UserRole} from "@/utils/auth.roles";

export default {
  name: 'NavbarNav',
  computed: {
    ...mapState('buySale', ['information']),
    getName() {
      return this.$store.state.userName;
    },
  },
  data: () => ({
    viewOldNotification: false,
    userId: '',
    rol: '',
    username: '',
    software: [],
    chats: [],
    credits: [],
    general: [],
    statusCollapse: {
      n_software: false,
      n_chats: false,
      n_credits: false,
      n_general: false,
      n_company: false,
      useractions: false
    },
    nativeNotification: {
      title: null,
      options: {
        body: null,
        renotify: true,
        tag: null
      },
      events: {}
    },
    snotify: {}
  }),
  async mounted() {
    await this.getNameStorage();
    await this.getRealtimeData();
    this.rol = JSON.parse(getDataStorage('admin-type'));
    await this.getOldNotifications();
    this.subscribeReloadOldNotifications()
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (isJustShown) {
        Object.keys(this.statusCollapse)
          .forEach(key => {
            if (this.statusCollapse[key] && key !== collapseId) {
              this.statusCollapse[key] = false;
            }
          });
      }
    });
    window.addEventListener('click', this.onclickOutside);
    this.loadCounterData().then()
  },

  methods: {
    ...mapMutations(['userNameManager']),
    ...mapActions('buySale', ['loadInformation']),
    ...mapActions('priceList', ['loadInformationPriceList']),
    ...mapActions('counterInformation', ['loadCounterData']),

    async getNameStorage() {
      this.username = getDataStorage('admin-name');
      this.userNameManager(this.username);
      this.userId = getDataStorage('admin-id');
    },

    async getRealtimeData() {
      this.sockets.subscribe('notify', (element) => {
        element = (Array.isArray(element)) ? element : [element];
        this.showNativeNotifications(element)
        this.validateLogout(element)
        this.filterInformation(element);
        if (!this.information.rol.includes('administrator')) {
          this.loadInformation();
        }
      });
    },

    showNativeNotifications(arrayData) {
      this.$notification.show(arrayData[0].title, {
        body: arrayData[0].text,
        renotify: true,
        tag: `${arrayData[0].type}}`
      }, {})
    },

    validateLogout(notification) {
      const result = notification.find(x => x.text === 'User Deactivated')
      if (result !== undefined && !this.$userCan('administrator')) {
        this.logout()
      }
    },

    async getOldNotifications() {
      this.viewOldNotification = !this.viewOldNotification;
      if (this.viewOldNotification) {
        const {data} = await get(allNotifications, null, true);
        if (Number(data.statusCode) === 200) {
          const element = (Array.isArray(data.message)) ? data.message : [data.message];
          await this.filterInformation(element);
        }
      }
    },

    subscribeReloadOldNotifications() {
      this.sockets.subscribe('reload-old-notifications', () => {
        if (!this.$userCan('administrator')) {
          this.$router.push({name: 'credit-dashboard'})
        }
      });
    },

    async filterInformation(data) {
      try {
        this.credits = (this.credits.length) ? this.credits.concat(data.filter(x => x.type.toString() === 'credits')) : data.filter(x => x.type.toString() === 'credits');
        this.chats = (this.chats.length) ? this.chats.concat(data.filter(x => x.type.toString() === 'chats')) : data.filter(x => x.type.toString() === 'chats');
        this.software = (this.software.length) ? this.software.concat(data.filter(x => x.type.toString() === 'software')) : data.filter(x => x.type.toString() === 'software');
        this.general = (this.general.length) ? this.general.concat(data.filter(x => x.type.toString() === 'general')) : data.filter(x => x.type.toString() === 'general');
        const temp = (this.general.length) ? this.general.concat(data.filter(x => x.type.toString() === 'priceList')) : data.filter(x => x.type.toString() === 'priceList');
        if (temp.length > 0) {
          this.loadInformationPriceList().then();
          this.general = this.general.concat(temp);
        }
        this.groupCreditsNotification();
      } catch (e) {}
    },

    groupCreditsNotification() {
      this.credits = this.groupNotification(this.credits);
      this.chats = this.groupNotification(this.chats);
      this.software = this.groupNotification(this.software);
      this.general = this.groupNotification(this.general);
    },

    groupNotification(dataArray) {
      const tempArray = {};
      dataArray.forEach((item) => {
        if (!tempArray.hasOwnProperty(item.itemId)) {
          tempArray[item.itemId] = [item];
        } else {
          tempArray[item.itemId].push(item);
        }
      });
      dataArray = [];
      for (const keyGroup in tempArray) {
        const array = tempArray[keyGroup];
        let notify = array[array.length - 1];
        notify.count = array.length;
        notify.ids = array.map(el => el.id)
        dataArray.push(notify);
      }
      return dataArray;
    },

    async logout() {
      this.$socket.close();
      try {
        localStorage.clear();
        window.localStorage.clear();
        this.$store.commit('setUser', null);
        this.$router.push({name: 'login'}).then().catch();
        localStorage.setItem('show-modal-help', 'true')
        window.location.reload()
      } catch (e) {
        await this.logout();
      }
    },

    async clearNotifications(type, arrayList) {
      if (type.toString() === 'software') {
        this.software = [];
      } else if (type === 'credits') {
        this.credits = [];
      } else if (type === 'general') {
        this.general = [];
      } else if (type === 'chats') {
        this.chats = [];
      }
      const ids = [];
      arrayList.forEach(x => ids.push(...x.ids));
      await post(clearNotifications, {
        ids: ids,
        type: type
      }, true);
    },

    async viewNotification(index, id, arrayList, type) {
      const tempId = (isArray(id)) ? id[id.length - 1] : id
      const element = arrayList.find(x => Number(x.id) === Number(tempId));
      arrayList.splice(index, 1);
      if (!arrayList.length) {
        Object.keys(this.statusCollapse)
          .forEach(k => this.statusCollapse[k] = false);
      }
      await get(readNotifications + '/' + id, null, true);
      const cryp = encrypt(JSON.stringify({id: element.itemId}))
      const routerObject = {
        name: element.url,
        params: {id: (type === 'software') || (element.url === 'order-detail' && element.type === 'chats') ? cryp : element.itemId}
      };
      if (element.url === 'order-detail' && element.type === 'chats') {
        routerObject['query'] = {'open-chat': 1};
        this.$router.push(routerObject).catch((e) => {
        });
      } else if (`${this.$route.name.toString()}/${this.$route.params.id}` === `${element.url.toString()}/${element.itemId}`) {
        window.location.reload()
      } else {
        this.$router.push(routerObject).catch((e) => {
        });
      }
    },

    async closeNotification(index, id, arrayList) {
      await get(readNotifications + '/' + id, null, true);
      arrayList.splice(index, 1);
    },

    onclickOutside(event) {
      try {
        if (!this.$refs.navPanel.contains(event.target)) {
          Object.keys(this.$refs)
            .forEach(key => {
              if (this.$refs[key].$el && !this.$refs[key].$el.contains(event.target)) {
                Object.keys(this.statusCollapse)
                  .forEach(el => {
                    this.statusCollapse[el] = false;
                  });
                // this.$refs.currency.status = false;
                return true;
              }
            });
        }
      } catch (e) {}
    },

    redirectTo() {
      if (this.$route.name.toString() !== 'profile') {
        this.$router.push('profile').catch()
      }
    },

    verifyLength(type, id) {
      return this[`${type}`].find(x => Number(x.itemId) === Number(id));
    }
  },

  destroyed() {
    window.removeEventListener('click', this.onclickOutside);
  },

  watch: {
    chats: function () {
      if (this.$userCan([UserRole.Calibrator])) {
        this.$snotify.clear()
        for (let [index, chat] of this.chats.entries()) {
          if (chat.url.includes('order-detail')) {
            chat.snotify = this.$snotify.error(chat.title, chat.text, {
              icon: false,
              closeOnClick: true,
              timeout: 0,
              buttons: [
                {
                  text: 'Open', action: (toast) => {
                    this.$snotify.remove(toast.id)
                    this.viewNotification(index, chat.id, this.chats, 'chats')
                  }
                }
              ],
            });
            chat.snotify.on('click', () => {
              this.closeNotification(index, chat.id, this.chats)
            })
          }
        }
      }
    }
  }
};
</script>

<style lang="scss">
.btn--wiggle {
  animation: 1s wiggle ease infinite;
}

@keyframes wiggle {
  0% {
    transform: rotate(-3deg);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
  20% {
    transform: rotate(20deg);
  }
  40% {
    transform: rotate(-15deg);
  }
  60% {
    transform: rotate(5deg);
  }
  90% {
    transform: rotate(-1deg);
  }
  100% {
    transform: rotate(0);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  }
}

@mixin afterBg {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
}

.btn--shockwave {
  box-shadow: 0 0 3px #000000;
  text-shadow: 0 0 3px #000000;
  font-size: 1vw;
  font-weight: bolder;
  animation: shockwaveJump 1s ease-out infinite;

  & :after {
    @include afterBg;
    animation: shockwave 1s 0.65s ease-out infinite;
  }

  & :before {
    @include afterBg;
    animation: shockwave 1s 0.5s ease-out infinite;
  }
}

.bell {
  box-shadow: none !important;
}

@keyframes shockwaveJump {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.18);
  }
  50% {
    transform: scale(0.98);
  }
  55% {
    transform: scale(1.12);
  }
  60% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shockwave {
  0% {
    transform: scale(1);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), inset 0 0 1px rgb(255, 0, 0);
  }
  95% {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0), inset 0 0 30px rgba(0, 0, 0, 0.678);
  }
  100% {
    transform: scale(2.25);
  }
}

.nav-link:hover {
  cursor: pointer;
}

/* IE11 Navbar flex fix. */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .navbar-nav {
    align-items: stretch !important;
    flex: 1 1 100%;
    flex-flow: row wrap;
  }

  .nav-item.notifications {
    margin-left: auto !important;
  }
}

.new-order {
  font-weight: bold !important;
  background-color: #00000046;
}

.table-saved2 {
  animation: shockwaveJump 1s ease-out infinite;
  font-weight: bold;

  & :after {
    @include afterBg;
    animation: shockwave 1s 0.65s ease-out infinite;
  }

  & :before {
    @include afterBg;
    animation: shockwave 1s 0.5s ease-out infinite;
  }
}

@keyframes shockwaveJump {
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.08);
  }
  50% {
    transform: scale(0.98);
  }
  55% {
    transform: scale(1.02);
  }
  60% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shockwave {
  0% {
    transform: scale(1);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.945), inset 0 0 1px rgba(0, 0, 0, 0.459);
  }
  95% {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0), inset 0 0 30px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(2.25);
  }
}

.heart {
  width: 100%;
  color: white;
  animation: beat2 1s infinite alternate;
}

/* Heart beat animation */
@keyframes beat2 {
  to {
    box-shadow: 0 0 10px #d14343;
  }
}

.dropdown-menu-max {
  max-height: 25vw !important;
  overflow-y: scroll !important;
}

@media only screen and (max-width: 350px) {
  .item_mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 400px) {
  .menu_item_header {
    width: 80% !important;
  }
}

</style>

<template>
  <b-container fluid>
    <b-row>
      <main class="main-content col-lg-12 col-md-12 col-sm-12 p-0">
        <!-- Main Navbar -->
        <div :class="['main-navbar', 'bg-white']">
          <b-container class="p-0">
            <b-navbar class="align-items-stretch flex-md-nowrap p-0" type="light">
              <a class="navbar-brand" href="#" style="line-height: 25px;">
                <div class="d-table m-auto">
                  <span class="d-none d-md-inline ml-1">TVS</span>
                </div>
              </a>
<!--              <navbar-search/>-->
              <navbar-nav class="d-flex ml-auto"/>
              <navbar-toggle v-d-toggle.nav1 class="border-left"/>
            </b-navbar>
          </b-container>
        </div>
        <!-- Header Navbar -->
        <header-navbar id="nav1" :items="headerNavbarItems" tag="d-collapse"/>
        <!-- Content -->
        <slot/>
        <!-- Main Footer -->
        <main-footer contained/>
      </main>
    </b-row>
  </b-container>
</template>

<script>
import getHeaderNavbarItems from '@/data/header-nav-items';

import MainNavbar from '@/components/layout/MainNavbar/MainNavbar.vue';
import NavbarSearch from '@/components/layout/MainNavbar/NavbarSearch.vue';
import NavbarNav from '@/components/layout/MainNavbar/NavbarNav.vue';
import NavbarToggle from '@/components/layout/MainNavbar/NavbarToggle.vue';
import HeaderNavbar from '@/components/layout/HeaderNavbar/HeaderNavbar.vue';
import MainFooter from '@/components/layout/MainFooter/MainFooter.vue';

export default {
  components: {
    MainNavbar,
    NavbarSearch,
    NavbarNav,
    NavbarToggle,
    HeaderNavbar,
    MainFooter,
  },
  data() {
    return {
      headerNavbarItems: getHeaderNavbarItems(),
    };
  },
};
</script>

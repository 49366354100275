import {get} from "@/services/api";
import {randomPastelColor} from '@/utils/colors';
import * as moment from "moment"
import {getPercentageIncrease} from "@/utils";
const data_set_structure = (char = 'horizontalBar') => ([
  {
    label: 'Current',
    data: [],
    borderColor: char === 'horizontalBar' ? "#17c671" : [],
    backgroundColor: char === 'horizontalBar' ? "#17c671" : [],
    fill: false,
  },
  {
    label: 'Previous',
    data: [],
    borderColor: char === 'horizontalBar' ? "#ffb400" : [],
    backgroundColor: char === 'horizontalBar' ? "#ffb400" : [],
    fill: false,
  },
])
const balanceInterface = {
  received: 0,
  used: 0,
  remaining: 0
}
const userInterface = {
  id: null,
  parent: null,
  children: [],
  username: null,
  email: null,
  country: null,
  variation: {
    received: 0,
    used: 0,
    remaining: 0
  },
  colors: {
    backgroundColor: '',
    borderColor: '',
    pointBackgroundColor: '',
    pointHoverBackgroundColor: '',
  },
  current: balanceInterface,
  previous: balanceInterface
}

export default {
  namespaced: true,
  state: {
    users: [userInterface],
    params: {
      time: 'month',
      from_previous: null,
      to_previous: null,
      from_current: null,
      to_current: null,
      compare: 'week',
      market: false,
      page: 'purchase',
      graphic: 'horizontalBar',
    },
    users_selected: [],
    purchase_graphic: {
      type: 'horizontalBar',
      labels: [],
      original_labels: [],
      data: [{dataset: [], user_id: null}],
    },
    purchase_total: {
      current: {received: 0, used: 0, remaining: 0},
      previous: {received: 0, used: 0, remaining: 0},
      variation: {received: 0, used: 0, remaining: 0}
    },
    purchase_column_selected: 'received', // remaining, received, used
    show_distributor_balance: true
  },
  mutations: {
    setUsers: (state, payload) => {
      state.users = payload
    },
    setParams: (state, payload) => {
      state.params = payload
    },
    setPurchaseTotal: (state, payload) => {
      state.purchase_total = payload;
    },
    setGraphicType: (state, payload) => {
      state.purchase_graphic.type = payload;
    },
    setPurchaseUserSelected: (state, payload) => {
      state.users_selected = payload;
    },
    setPurchaseColumnSelected: (state, payload) => {
      state.purchase_column_selected = payload
    },
    setShowDistributorBalance: (state, payload) => {
      state.show_distributor_balance = payload
    }
  },
  getters: {
    purchase_graphic: state => {
      return processData(state.users, state.users_selected, state.purchase_column_selected, state.purchase_graphic.type)
    },
    purchase_graphic_type: state => {
      return state.purchase_graphic.type
    }
  },
  actions: {
    fetchUsers({commit, state}) {
      get(`reports/credit?time=${state.params.time}&from_previous=${state.params.from_previous}&to_previous=${state.params.to_previous}&from_current=${state.params.from_current}&to_current=${state.params.to_current}`,
        null, true).then(({data}) => {
        calculate_variation(data.users)
        const total = calculatePurchaseTotal(data.users)
        const total_variation = calculateTotalVariation(data.users)
        commit("setUsers", data.users)
        commit("setPurchaseTotal", {...total, variation: total_variation})
        commit("setParams", {...state.params, ...data.params})
      })
    },
    groupDealersBalanceDistributor({state, commit, dispatch}) {
      if (state.show_distributor_balance) {
        dispatch("fetchUsers")
      } else {
        const users = sumDealersBalance(state.users)
        commit("setUsers", users)
      }
    }
  }
}

function calculate_variation(users) {
  const setValues = (user) => {
    const color = randomPastelColor()
    // const color = randomColor()
    user.variation.received = getPercentageIncrease(user.previous.received, user.current.received)
    user.variation.used = getPercentageIncrease(user.previous.used, user.current.used)
    user.variation.remaining = getPercentageIncrease(user.previous.remaining, user.current.remaining)
    user.colors = {
      backgroundColor: color,
      borderColor: color,
      pointBackgroundColor: color,
      pointHoverBackgroundColor: color,
    }
    return user
  }
  for (let user of users) {
    user = setValues(user)
    if (user.children.length) {
      for (let child of user.children) {
        child = setValues(child)
      }
    }
  }
}

function processData(users, ids = [], column_selected = 'received', char_type = 'horizontalBar') {
  const labels = [];
  const data_sets = data_set_structure(char_type)
  const setData = (item) => {
    const color = randomPastelColor()
    item.colors = {
      backgroundColor: color, borderColor: color, pointBackgroundColor: color, pointHoverBackgroundColor: color,
    }
    if(column_selected === 'used' && char_type !== 'pie'){
      data_sets.forEach(el => {
        if(el.label ==="Current"){
          el.backgroundColor = '#3F47CC';
          el.borderColor = '#3F47CC';
        }else{
          el.backgroundColor = '#ED1B24';
          el.borderColor = '#ED1B24';
        }
      })
      data_sets[0].data.push(Number((item.current[column_selected] * -1).toFixed(0)))
      data_sets[1].data.push(Number((item.previous[column_selected] * -1).toFixed(0)))
    }else{
      data_sets[0].data.push(Number((item.current[column_selected]).toFixed(0)))
      data_sets[1].data.push(Number((item.previous[column_selected]).toFixed(0)))
    }
    if (char_type === 'pie') {
      data_sets[0].backgroundColor.push(item.colors.backgroundColor)
      data_sets[1].backgroundColor.push(item.colors.backgroundColor)
    }
    labels.push(item.username)
  }

  users.forEach(user => {
    if (!ids.length || ids.includes(user.id))
      setData(user)
    user.children.forEach(child => {
      if (!ids.length || ids.includes(child.id))
        setData(child)
    })
  })
  return {data: data_sets, labels}
}

function calculatePurchaseTotal(users) {
  const current = {
    received: 0,
    used: 0,
    remaining: 0
  }
  const previous = {
    received: 0,
    used: 0,
    remaining: 0
  }
  current.received = users.reduce((prev, curr) => prev + curr.current.received, 0)
  current.used = users.reduce((prev, curr) => prev + curr.current.used, 0)
  current.remaining = users.reduce((prev, curr) => prev + curr.current.remaining, 0)

  previous.received = users.reduce((prev, curr) => prev + curr.previous.received, 0)
  previous.used = users.reduce((prev, curr) => prev + curr.previous.used, 0)
  previous.remaining = users.reduce((prev, curr) => prev + curr.previous.remaining, 0)
  return {current, previous}
}

function calculateTotalVariation(users) {
  const totalVariation = {
    received: 0,
    used: 0,
    remaining: 0
  }
  totalVariation.received = users.reduce((prev, curr) => prev + curr.variation.received, 0)
  totalVariation.used = (users.reduce((prev, curr) => prev + curr.variation.used, 0)) / users.length
  totalVariation.remaining = (users.reduce((prev, curr) => prev + curr.variation.remaining, 0)) / users.length
  totalVariation.received = totalVariation.received / users.length
  totalVariation.used = totalVariation.used / users.length
  totalVariation.remaining = totalVariation.remaining / users.length
  return totalVariation
}

function sumDealersBalance(users) {
  users.forEach(user => {
    if (user.children.length) {
      user = setZeroValues(user)
      user.children.forEach(child => {
        Object.keys(user.current).forEach((field) => user.current[field] += child.current[field])
        Object.keys(user.previous).forEach((field) => user.previous[field] += child.previous[field])
      })
    }
  })
  return users
}

function setZeroValues(user) {
  Object.keys(user.current).forEach((field) => user.current[field] = 0)
  Object.keys(user.previous).forEach((field) => user.previous[field] = 0)
  return user
}


import {getDataStorage} from '@/helpers/localStorage';
import {decrypt} from "@/helpers/encrypt";

export default (to, from, next) => {
  try {
    if (!to.meta.requiresAuth) {
      next();
    } else if (to.matched.some(record => record.meta.requiresAuth)) {
      const role = JSON.parse(getDataStorage('admin-type'));
      const user = JSON.parse(decrypt(localStorage.getItem('tvs-user')))
      if (user && user.twoFactor && !user.twoFactorPass) {
        next('two-factor')
      }
      if (role) {
        const arrayRole = to.matched.filter(x => x.meta.roles).map(x => x.meta.roles);
        if (arrayRole.every(x => x.some(r => role.includes(r)))) {
          next();
        } else {
          next('/login');
        }
      } else {
        next('/login');
      }
    } else {
      next();
    }
  } catch (e) {
    console.error(e);
  }
}

import {get} from "@/services/api";
import {randomPastelColor} from "@/utils/colors";
import {getPercentageIncrease} from "@/utils";
import {reports_array_dsg, reports_array_stages} from "@/constants";

const data_set_structure = (char = 'horizontalBar') => ([
  {
    label: 'Current',
    data: [],
    borderColor: char === 'horizontalBar' ? "#17c671" : [],
    backgroundColor: char === 'horizontalBar' ? "#17c671" : [],
    fill: false,
  },
  {
    label: 'Previous',
    data: [],
    borderColor: char === 'horizontalBar' ? "#ffb400" : [],
    backgroundColor: char === 'horizontalBar' ? "#ffb400" : [],
    fill: false,
  },
])

export default {
  namespaced: true,
  state: {
    users: [],
    users_selected: [],
    stages: [],
    unit_control: reports_array_dsg(),
    show_distributor_balance: true,
    params: {
      time: 'month',
      from_previous: null,
      to_previous: null,
      from_current: null,
      to_current: null,
      compare: 'week',
      page: 'default',
      graphic: 'horizontalBar',
      order_status: null,
      order_type: 'all',
      order_stage: null,
      unit_control: null,
    },
    dashboard_graphic: {
      type: 'horizontalBar',
      labels: [],
      original_labels: [],
      data: [],
    },
    column_selected: 'price', // price, total_rows , support
  },
  mutations: {
    setParams: (state, payload) => {
      state.params = payload
    },
    setGraphicType: (state, payload) => {
      state.dashboard_graphic.type = payload
    },
    setUsers: (state, payload) => {
      state.users = payload
    },
    setUsersSelected: (state, payload) => {
      state.users_selected = payload
    },
    setColumSelected: (state, payload) => {
      state.column_selected = payload
    },
    setShowDistributorBalance: (state, payload) => {
      state.show_distributor_balance = payload
    }
  },
  getters: {
    dashboard_graphic: state => {
      return processData(state.users, state.users_selected, state.column_selected, state.dashboard_graphic.type)
    },
    dashboard_graphic_type: state => {
      return state.dashboard_graphic.type
    },
  },
  actions: {
    fetchSoftwareOrders({commit, state}) {
      get(`reports/software-orders?order_type=${encodeURIComponent(state.params.order_type)}&stage=${encodeURIComponent(state.params.order_stage)}&time=${state.params.time}&status=${state.params.order_status}&unit=${state.params.unit_control}&from_previous=${state.params.from_previous}&to_previous=${state.params.to_previous}&from_current=${state.params.from_current}&to_current=${state.params.to_current}`, null, true).then(({data}) => {
        const model = calculate_variation(data.users)
        commit("setUsers", model)
        commit("setParams", {
          ...state.params,
          from_previous: data.params.from_previous,
          to_previous: data.params.to_previous,
          from_current: data.params.from_current,
          to_current: data.params.to_current
        })

      })
    },
    fetchCombos({state}) {
      state.stages = ['ECU', 'DSG', 'ECU + DSG'].includes(state.params.order_type) ? reports_array_stages().find(x => x.key === state.params.order_type).value : []
      state.params.order_stage = null
    },
    groupDealersBalanceDistributor({state, commit, dispatch}) {
      if (state.show_distributor_balance) {
        dispatch("fetchSoftwareOrders")
      } else {
        const users = sumDealersBalance(state.users)
        commit("setUsers", users)
      }
    }
  }
}

function calculate_variation(users) {
  const setValues = (user) => {
    const color = randomPastelColor()
    user.variation.credits = getPercentageIncrease(user.previous.price, user.current.price)
    user.variation.tune = getPercentageIncrease(user.previous.total_rows, user.current.total_rows)
    user.variation.support = getPercentageIncrease(user.previous.support, user.current.support)
    user.colors = {
      backgroundColor: color,
      borderColor: color,
      pointBackgroundColor: color,
      pointHoverBackgroundColor: color,
    }
    return user
  }

  for (let user of users) {
    user = setValues(user)
    if (user.children.length) {
      for (let child of user.children) {
        child = setValues(child)
      }
    }
  }
  return users
}

function processData(users, ids = [], column_selected = 'price', char_type = 'horizontalBar') {
  const labels = [];
  const data_sets = data_set_structure(char_type)
  const setData = (item) => {
    const color = randomPastelColor()
    item.colors = {
      backgroundColor: color, borderColor: color, pointBackgroundColor: color, pointHoverBackgroundColor: color,
    }
    data_sets[0].data.push(Number((item.current[column_selected]).toFixed(0)))
    data_sets[1].data.push(Number((item.previous[column_selected]).toFixed(0)))

    if (char_type === 'pie') {
      data_sets[0].backgroundColor.push(item.colors.backgroundColor)
      data_sets[1].backgroundColor.push(item.colors.backgroundColor)
    }
    labels.push(item.username)
  }

  users.forEach(user => {
    if (!ids.length || ids.includes(user.id))
      setData(user)
    user.children.forEach(child => {
      if (!ids.length || ids.includes(child.id))
        setData(child)
    })
  })
  return {data: data_sets, labels}
}

function sumDealersBalance(users) {
  users.forEach(item => {
    if (item.children.length) {
      item = setZeroValues(item)
      item.children.forEach(value => {
        item.current.price += value.current.price
        item.current.total_rows += value.current.total_rows
        item.current.support += value.current.support
        item.previous.price += value.previous.price
        item.previous.total_rows += value.previous.total_rows
        item.previous.support += value.previous.support
      })
    }
  })
  return users
}

function setZeroValues(user) {
  user.current.price = 0
  user.current.total_rows = 0
  user.current.support = 0
  user.previous.price = 0
  user.previous.total_rows = 0
  user.previous.support = 0
  return user
}

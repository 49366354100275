import {decrypt, encrypt,} from "./encrypt";

const setDataStorage = (key, value) => {
  const data = encrypt(value);
  localStorage.setItem(key, data);
};

const getDataStorage = (key) => {
  if (localStorage.getItem(key)) {
    const data = localStorage.getItem(key);
    return decrypt(data);
  } else return null;
};

const removeKeyStorage = (key) => {
  localStorage.removeItem(key);
};

const removeAllKeyStorage = () => {
  localStorage.clear();
  window.localStorage.clear();
};

export {setDataStorage, getDataStorage, removeKeyStorage, removeAllKeyStorage};

import state from '@/store/sub-user-permission'
import store from "@/store";

export function checkSubUserPermission(process) {
  const toCheck = process.split('.')
  let current = {process: state.state.subUserPermissions};
  toCheck.forEach(key => {
    if (current)
      current = current.process.find(el => el.value === key)
  });
  return current ? current.status : true;
}

export const permission = (primaryOption = false, moduleName = 'sale', value) => {
  const rules = store.state.buySale.information.permissions
  const select = rules.find(el => el.value === moduleName)
  if (select !== undefined) {
    if (primaryOption) {
      return select.status
    } else {
      const item = select.process.find(x => x.value === value)
      return (item !== undefined) ? item.status : false
    }
  } else {
    return false
  }
}

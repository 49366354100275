import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import Vue from 'vue';
import CoolLightBox from 'vue-cool-lightbox'

export default {
  components: {
    CoolLightBox,
  },
}

Vue.use(CoolLightBox)

import {get} from '@/services/api';

export default {
  namespaced: true,
  state: {
    ticketStatusTypes: [],
    ticketCategoryTypes: [],
  },
  mutations: {
    setStatusTypes: (state, payload) => {
      state.ticketStatusTypes = payload;
    },
    setCategoryTypes: (state, payload) => {
      state.ticketCategoryTypes = payload;
    }
  },
  getters: {
    getItemsGroup: (state) => (g) => {
      return state.ticketCategoryTypes.filter(el => {
        return el.menuGroup === g;
      });
    },
    getCategoryById: state => (id) => {
      return state.ticketCategoryTypes.find(el => el.id === parseInt(id));
    }
  },
  actions: {
    async loadCategoryTypes({commit}) {
      return new Promise(resolve => {
        get('support-category', null, true)
          .then(response => {
            commit('setCategoryTypes', response.data);
            resolve(response.data);
          });
      });

    },
    loadStatusTypes({commit}) {
      get('support-status')
        .then(response => {
          commit('setStatusTypes', response.data);
        });
    },
  }
};

<template  class="">
  <b-container class="h-100" fluid >
    <menu-header/>
    <b-row class="h-100">
      <b-col class="main-content p-0 col-xs-12" lg="12" md="9" sm="12" tag="main">
        <slot/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import MenuHeader from '@/components/menu_header/index.vue';

export default {
  name: 'Auth',
  components: {MenuHeader},
  data() {
    return {};
  },
};
</script>


import {get} from "@/services/api";
import {randomPastelColor} from "@/utils/colors";
import {reports_array_dsg, reports_array_stages} from "@/constants";
import {getPercentageIncrease} from "@/utils";

const data_set_structure = (char) => ([
  {
    label: 'Credits balance',
    data: [],
    borderColor: char === 'horizontalBar' ? "#17c671" : [],
    backgroundColor: char === 'horizontalBar' ? "#17c671" : [],
  },
  {
    label: 'Outstanding charges',
    data: [],
    borderColor: char === 'horizontalBar' ? "#ffb400" : [],
    backgroundColor: char === 'horizontalBar' ? "#ffb400" : [],
  },
  {
    label: 'Expected balance',
    data: [],
    borderColor: char === 'horizontalBar' ? "#FF4169" : [],
    backgroundColor: char === 'horizontalBar' ? "#FF4169" : [],
  },
])

export default {
  namespaced: true,
  state: {
    pending_users_summary: [],
    users_selected: [],
    stages: [],
    unit_control: reports_array_dsg(),
    params: {
      time: 'month',
      from_previous: null,
      to_previous: null,
      from_current: null,
      to_current: null,
      compare: 'week',
      page: 'default',
      graphic: 'horizontalBar',
      order_status: null,
      order_type: 'all',
      order_stage: null,
      unit_control: null,
    },
    pending_graphic: {
      type: 'horizontalBar',
      labels: [],
      original_labels: [],
      data: [
        {
          label: null,
          data: [],
          backgroundColor: null
        }
      ],
    },
    column_selected: 'credits',
    show_distributor_balance: true
  },
  mutations: {
    setParams: (state, payload) => {
      state.params = payload
    },
    setUsers: (state, payload) => {
      state.pending_users_summary = payload
    },
    setUsersSelected: (state, payload) => {
      state.users_selected = payload
    },
    setGraphicType: (state, payload) => {
      state.pending_graphic.type = payload
    },
    setShowDistributorBalance: (state, payload) => {
      state.show_distributor_balance = payload
    }
  },
  getters: {
    pending_graphic: state => {
      return processData(state.pending_users_summary, state.users_selected, state.column_selected, state.pending_graphic.type)
    },
    pending_graphic_type: state => {
      return state.pending_graphic.type
    }
  },
  actions: {
    fetchSoftware({commit, state}) {
      get(`reports/software-pending-summary?time=${state.params.time}&from_previous=${state.params.from_previous}&to_current=${state.params.to_current}`, null, true)
        .then(({data}) => {
          commit("setUsers", data)
        })
    },
    fetchCombos({state}) {
      state.stages = ['ECU', 'DSG', 'ECU + DSG'].includes(state.params.order_type) ? reports_array_stages().find(x => x.key === state.params.order_type).value : []
      state.params.order_stage = null
    },
    groupDealersBalanceDistributor({state, commit, dispatch}) {
      if (state.show_distributor_balance) {
        dispatch("fetchSoftware")
      } else {
        const users = sumDealersBalance(state.pending_users_summary)
        commit("setUsers", users)
      }
    }
  }
}

export function processData(users, ids = [], column_selected = 'credits', char_type = 'horizontalBar') {
  const labels = [];
  const data_sets = data_set_structure(char_type)
  const setData = (item) => {
    const color = randomPastelColor()
    item.colors = {
      backgroundColor: color, borderColor: color, pointBackgroundColor: color, pointHoverBackgroundColor: color,
    }
    data_sets[0].data.push(Number((item.movements.price).toFixed()))
    data_sets[1].data.push(Number(item.orders.price).toFixed(0))
    data_sets[2].data.push(Number((item.balance).toFixed(0)))
    if (char_type === 'pie') {
      data_sets[0].backgroundColor.push(item.colors.backgroundColor)
      data_sets[1].backgroundColor.push(item.colors.backgroundColor)
      data_sets[2].backgroundColor.push(item.colors.backgroundColor)
    }
    labels.push(item.username)
  }

  users.forEach(user => {
    if (!ids.length || ids.includes(user.id))
      setData(user)
    user.children.forEach(child => {
      if (!ids.length || ids.includes(child.id))
        setData(child)
    })
  })
  return {data: data_sets, labels}
}

function sumDealersBalance(users) {
  users.forEach(item => {
    if (item.children.length) {
      item = setZeroValues(item)
      item.children.forEach(value => {
        item.movements.price += value.movements.price
        item.movements.total_rows += value.movements.total_rows
        item.orders.price += value.orders.price
        item.orders.total_rows += value.orders.total_rows
      })
    }
  })
  return users
}

function setZeroValues(user) {
  user.movements.price = 0
  user.movements.total_rows = 0
  user.orders.price = 0
  user.orders.total_rows = 0
  return user
}

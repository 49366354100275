import {get} from '@/services/api';
import {userCan} from '@/utils';
import {UserRole} from '@/utils/auth.roles';

export default {
  namespaced: true,
  state: {
    currencies: [],
    countries: [],
    userCountry: {},
    userCurrency: {},
    tvsCurrency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
      price: 1,
      locale: 'nl-NL'
    },
    showInLocal: false,
    showSwitch: true
  },
  mutations: {
    setCurrencies(state, value) {
      state.currencies = value;
    },
    setCountries(state, value) {
      state.countries = value;
    },
    setUserCountry(state, value) {
      state.userCountry = value;
    },
    setUserCurrency(state, value) {
      state.userCurrency = value;
      localStorage.setItem('currency-code', value.code);
    },
    switchShowCredits: state => {
      state.showInLocal = !state.showInLocal;
    }
  },
  getters: {
    currencyToUser: (state) => (euro, currencyDetails = null, withMarket = false) => {
      if (currencyDetails === null) {
        currencyDetails = state.userCurrency;
      }
      if (currencyDetails) {
        let amount = withMarket ? (euro * state.userCountry.marketFactor) * currencyDetails.price : euro * (currencyDetails.price);
        const formater = new Intl.NumberFormat(currencyDetails.locale, {
          style: 'currency',
          currency: currencyDetails.code,
          minimumFractionDigits: 2,
          currencyDisplay: 'symbol'
        });
        return formater.format(amount);
      }
      return euro;
    },
    formatToCurrency: (state) => (amount, currencyDetails = null) => {
      if (currencyDetails === null) {
        if (state.showInLocal)
          currencyDetails = state.userCurrency;
        else
          currencyDetails = state.tvsCurrency
      }
      if (currencyDetails) {
        const formatter = new Intl.NumberFormat(currencyDetails.locale, {
          style: 'currency',
          currency: currencyDetails.code,
          minimumFractionDigits: 2,
          currencyDisplay: 'symbol'
        });
        return formatter.format(amount);
      }
      return amount;
    },
    userCountryPrice: (state) => euro => {
      return euro * state.userCountry.marketFactor;
    },
    priceFor: (state) => (euro, currencyDetails) => {
      if (state.countries.length) {
        if (typeof currencyDetails === 'string') {
          currencyDetails = JSON.parse(currencyDetails);
        }
        const amount = euro * currencyDetails.currency_price;
        const formater = new Intl.NumberFormat(currencyDetails.country_locale, {
          style: 'currency',
          currency: currencyDetails.currency_code,
          minimumFractionDigits: 2,
          currencyDisplay: 'symbol'
        });
        return formater.format(amount);
      }
      return euro;
    },
    creditsToLocalCredits: (state, getters) => (euro, currencyDetails = null) => {
      if (state.userCurrency) {
        if (currencyDetails === null) {
          currencyDetails = state.userCurrency;
        }
        let amount = euro * (currencyDetails.price);
        if (userCan([UserRole.Admin])) {
          amount = getters.localCreditsToTvsCredits(amount, currencyDetails);
        }
        const formater = new Intl.NumberFormat(currencyDetails.locale, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
        return formater.format(amount);
      }
      return euro;
    },
    currencyCreditSymbol: state => {
      if (!state.showInLocal) {
        return require(`../assets/images/credits/eur.svg`);
      }
      if (state.userCurrency) {
        const code = state.userCurrency.code.toLowerCase();
        try {
          return require(`../assets/images/credits/${code}.svg`);
        } catch (e) {
          return require(`../assets/images/credits/default.svg`);
        }
      }
    },
    localCreditSymbol: state => {
      if (state.userCurrency) {
        const code = state.userCurrency.code.toLowerCase();
        try {
          return require(`../assets/images/credits/${code}.svg`);
        } catch (e) {
          return require(`../assets/images/credits/default.svg`);
        }
      }
    },
    localCreditsToTvsCredits: state => (amount, currencyDetails = null) => {
      if (currencyDetails === null) {
        currencyDetails = state.userCurrency;
      }
      if (currencyDetails) {
        return (((1 / currencyDetails.price) * amount));
      }
    },
    tvsCreditsToLocalCredits: state => (amount, currencyDetails = null, forcePassCurrency = false) => {
      if (currencyDetails === null) {
        currencyDetails = state.userCurrency;
      }
      if (userCan([UserRole.Admin]) && !forcePassCurrency) {
        currencyDetails = state.tvsCurrency;
      }
      if (currencyDetails) {
        return ((currencyDetails.price * amount));
      }
    },
    country: (state) => (id) => {
      return state.countries.find(el => el.id === id);
    },
    getCurrencyByCountry: (state, getters) => countryId => {
      let country = getters.country(countryId);
      if (country) {
        return {
          code: country.currencyCode,
          symbol: country.currencySymbol,
          name: country.currencyName,
          price: country.currencyPrice,
          locale: country.locale
        };
      } else {
        return state.tvsCurrency;
      }
    },
    getCurrentCurrencyUsed: (state) => {
      if (state.showInLocal)
        return state.userCurrency
      else
        return state.tvsCurrency
    },
    numberFilter: (state) => (value, showDecimal = true) => {
      const options = (showDecimal) ? {maximumFractionDigits: 2, minimumFractionDigits: 2} : {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      }
      return new Intl.NumberFormat(state.userCurrency.locale, options).format(value);
    }
  },
  actions: {
    // deprecated
    loadCurrencies({commit}) {
      get('geo/currencies')
        .then(({data}) => {
          const mostImportant = ['USD', 'EUR', 'JPY', 'GBP', 'AUD', 'CAD', 'CHF', 'CNY', 'HKD', 'NZD'];
          let fisrt = data.filter(row => mostImportant.includes(row.currency_code))
            .sort((a, b) => {
              if (mostImportant.indexOf(a.currency_code) > mostImportant.indexOf(b.currency_code)) {
                return 1;
              } else {
                return -1;
              }
            })
            .map(el => {
              let locale = el.locale;
              return {
                ...el,
                locale
              };
            });
          let rest = data.filter(row => !mostImportant.includes(row.currency_code));
          commit('setCurrencies', [].concat(...fisrt, ...rest));
        });
    },
    loadCountries({commit}) {
      get('geo/countries')
        .then(({data}) => {
          commit('setCountries', data);
        });
    },
    getUserCountry({
                     commit,
                     dispatch
                   }, id) {
      get(`geo/currency-country/${id}`)
        .then(({data}) => {
          commit('setUserCountry', {
            id: data.id,
            name: data.name,
            nativeName: data.nativeName,
            alpha2Code: data.alpha2Code,
            alpha3Code: data.alpha3Code,
            marketFactor: data.marketFactor
          });
          commit('setUserCurrency', {
            code: data.currencyCode,
            symbol: data.currencySymbol,
            name: data.currencyName,
            price: data.currencyPrice,
            locale: data.locale
          });
        });
    },
    loadCurrencyByLocal({commit}, code) {
      get(`geo/currency/${code}`)
        .then(({data}) => {
          commit('setUserCurrency', {
            code: data.currencyCode,
            symbol: data.currencySymbol,
            name: data.currencyName,
            price: data.currencyPrice,
            locale: data.locale
          });
        });
    }
  }
};

import {get, post} from "@/services/api";

export default {
  namespaced: true,
  state: {
    users: [],
    users_selected: [],
    deals: [],
    deal_selected: null
  },
  mutations: {
    setUsers: (state, payload) => {
      state.users = payload
    },
    setUsersSelected: (state, payload) => {
      state.users_selected = payload
    },
    setDeals: (state, payload) => {
      state.deals = payload
    },
    setDealSelected: (state, payload) => {
      state.deal_selected = payload
    }
  },
  getters: {
    getUsers: (state) => {
      return state.users
    },
    getDeals: (state) => {
      return state.deals
    },
    getDealSelected: (state) => {
      return state.deal_selected
    }
  },
  actions: {
    fetchDeals({commit}) {
      get("crm-sales/get-deals", null, true).then(({data}) => {
        const values = [];
        [...new Set(data.map(x => x.title))].forEach(item => {
          values.push(data.find(x => x.title === item))
        })
        commit("setDeals", data)
      })
    },
    fetchUsers({commit}) {
      get("crm-sales/users", null, true).then(({data}) => {
        commit("setUsers", data.users)
      })
    },
    fetchCreatePromotion({dispatch}, payload) {
      return new Promise(resolve => {
        post("crm-sales/create-promotion", payload, true).then(({data}) => {
          dispatch("fetchDeals")
          dispatch("fetchUsers")
          resolve(data)
        })
      })
    }
  }
}

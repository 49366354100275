import {get, post} from '@/services/api';
import {filterEngine, filterGeneration, filterModel, getBrands, site} from '@/services/endpoint.json';
import {processDataSoftwareTunning} from '@/views/dashboard/create-software-order/processDataSoftwareTunning';
import {processDataDSGTunning} from '@/views/dashboard/create-software-order/processDataDSGTunning';
import {processCombos} from '@/views/dashboard/create-software-order/proccessCombos';
const init = () => ({
  autoSearch: true,
  manualSearch: false,
  filters: {
    brand: null,
    model: null,
    generation: null,
    engine: null,
    gearbox: null
  },
  params: {},
  brands: [],
  models: [],
  generations: [],
  engines: [],
  combosTunning: {combos: []},
  dsgTunning: [],
  sofwareTunning: [],
  arrayGearbox: [],
  cart: {
    vin: '',
    vehicleId: {auto_found: true},
    type: null,
    tuned_files: []
  },
  files: [],
  preOrden: null,
  combos: []
})
export default {
  namespaced: true,
  state: init(),
  mutations: {
    setParameters(state, payload) {
      state.params = payload;
      state.cart.type = payload.type;
    },
    updateFilter(state, payload) {
      state.filters[payload.key] = payload.value;
      if (state.cart.vehicleId) {
        state.cart.vehicleId[payload.key] = payload.value;
      }
    },
    setFilter(state, payload) {
      state.filters = Object.assign(state.filters, payload);
    },
    setBrands(state, payload) {
      state.brands = payload;
    },
    setData(state, payload) {
      state[payload.key] = payload.data;
    },
    setVehicleResult(state, payload) {
      state = Object.assign(state, payload);
    },
    cleanResult(state) {
      state.combosTunning = {combos: []};
      state.dsgTunning = [];
      state.sofwareTunning = [];
    },
    setVehicleInfo(state, payload) {
      state.cart = Object.assign(state.cart, payload);
    },
    switchSearchType(state) {
      state.autoSearch = !state.autoSearch;
      state.manualSearch = !state.manualSearch;
    },
    resetSearchType(state) {
      state.autoSearch = true;
      state.manualSearch = false;
    },
    showManualSearch(state, payload) {
      if (!payload) {
        state.manualSearch = !state.manualSearch;
      }
      state.manualSearch = payload;
    },
    autoSetVehicleInfo(state, payload) {
      let model = payload;
      let data = {
        id: model.id,
        name: model.make,
        model: model.model,
        generation: model.generation,
        engine: model.engine,
        engine_family: model.engineFamily,
        dsg: state.filters.gearbox ? state.filters.gearbox.split('_')[0] : model.dsg[0].split('_')[0],
        dsg_family: state.filters.gearbox ? state.filters.gearbox : model.dsg[0]
      };
      state.cart.vehicleId = Object.assign(state.cart.vehicleId, data);
    },
    files(state, files) {
      state.files = files;
    },
    clear(state) {
      state.cart = {
        vin: null,
        vehicleId: {},
        type: null,
      };
      state.filters = {
        brand: null,
        model: null,
        generation: null,
        engine: null,
        gearbox: null
      };
      state.combosTunning = {
        combos: []
      };
      state.dsgTunning = [];
      state.sofwareTunning = [];
      state.arrayGearbox = [];
    },
    clearResult(state) {
      state.cart = {
        vin: null,
        vehicleId: {},
        type: null,
      };
      state.combosTunning = {
        combos: []
      };
      state.dsgTunning = [];
      state.sofwareTunning = [];
    },
    putPreOrder(state, payload) {
      state.preOrden = payload;
    },
    setGearboxArray(state, payload) {
      state.arrayGearbox = payload
    },
    clearGearbox(state) {
      state.arrayGearbox = [];
      state.filters.gearbox = null;
    },
    setcombos(state, payload) {
      state.combos = payload;
    },
    resetFullOrderSoftwareState(state){
      state = init()
    }
  },
  getters: {
    getEcu: (state) => (id) => {
      return state.sofwareTunning.find(el => el.id == id);
    },
    getDsg: (state) => (id) => {
      return state.dsgTunning.find(el => el.id == id);
    },
    getCombo: (state) => (id) => {
      return state.combosTunning.combos.find(el => el.id == id);
    },
    getDescription: (state, getters) => {
      switch (state.params.type) {
        case 'ECU':
          return getters.getEcu(state.params.id).data
        case 'DSG':
          return getters.getDsg(state.params.id).data
        case 'ECU + DSG':
          let combo = getters.getCombo(state.params.id);
          return `${combo.description}`
      }
      return ''
    },
    getPreorderParams: (state) => (preOrden) => {
      if (!preOrden) {
        preOrden = state.preOrden;
      }
      return {
        type: preOrden.type,
        id: preOrden.stage
      };
    }
  },
  actions: {
    async loadBrands({
                       commit,
                       state
                     }) {
      if (!state.brands.length) {
        const {data} = await post(getBrands, null, false);
        commit('setBrands', data.message);
      }
    },
    async load({commit, state,}, filterAttr) {
      commit('cleanResult');
      let url = filterModel;
      let key = 'models';
      switch (filterAttr) {
        case 'models':
          state.generations = [];
          state.engines = [];
          state.arrayGearbox = [];
          state.filters.model = null;
          state.filters.generation = null;
          state.filters.engine = null;
          break;
        case 'generations':
          key = 'generations';
          url = filterGeneration;
          state.engines = [];
          state.arrayGearbox = [];
          state.filters.generation = null;
          state.filters.engine = null;
          break;
        case 'engines':
          key = 'engines';
          url = filterEngine;
          state.arrayGearbox = [];
          state.filters.engine = null;
          state.filters.gearbox = null;
      }
      const {data} = await post(url, state.filters, false);
      commit('setData', {
        key,
        data: data.message
      });
    },

    async loadDataFromFullFilter({commit, state}, includeEngine = false) {
      let info = {
        models: filterModel,
        generations: filterGeneration
      };
      if (includeEngine) {
        info['engines'] = filterEngine
      }
      Object.keys(info)
        .forEach(key => {
          post(info[key], state.filters, false)
            .then(({data}) => {
              commit('setData', {
                key,
                data: data.message
              });
            });
        });
    },

    async vehicleOptions({commit, state, rootState}, checkGearbox) {
      const params = {
        country: rootState.geo.userCountry.alpha2Code,
        ...state.filters
      }
      const {data} = await post(site, params, false);
      if (checkGearbox) {
        state.dsgTunning = [];
        state.combosTunning = {combos: []};
        state.sofwareTunning = [];
        state.cart.vehicleId.dsg = ' -- '
        if (data.message.tuning.dsg.length > 1) {
          data.message.tuning.dsg.map(item => {
            state.arrayGearbox.push({text: item.split('_')[0], value: item.trim()})
          });
        }
      }
      if ((!state.arrayGearbox.length || !checkGearbox)) {
        const sofwareTunning = processDataSoftwareTunning(data.message.tuning, data.message.stage_visibility);
        const dsgTunning = processDataDSGTunning(data.message.dsg, data.message.stage_visibility);
        const combosTunning = processCombos(data.message.combos, sofwareTunning, dsgTunning);
        commit('setVehicleResult', {
          combosTunning,
          sofwareTunning,
          dsgTunning
        });
        commit('autoSetVehicleInfo', data.message.tuning);
        return true;
      } else {
        return false
      }
    },

    async vehicleOptionsById({commit, state, rootState}, id) {
      const {data} = await get(`site/${id}?alphaCountry=${rootState.geo.userCountry.alpha2Code}`);
      const sofwareTunning = processDataSoftwareTunning(data.message.tuning, data.message.stage_visibility);
      const dsgTunning = processDataDSGTunning(data.message.dsg, data.message.stage_visibility);
      const combosTunning = processCombos(data.message.combos, sofwareTunning, dsgTunning);
      commit('setVehicleResult', {
        combosTunning,
        sofwareTunning,
        dsgTunning
      });
      commit('autoSetVehicleInfo', data.message.tuning);
    },
    setPreOrder({commit}, order) {
      if (order) {
        const items = order.description.item;
        commit('putPreOrder', {
          id: order.id,
          items,
          type: order.type,
        });
      } else {
        commit('putPreOrder', null);
      }
    }
  }
};

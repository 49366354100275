import {get, post} from "@/services/api";
import * as moment from "moment";

export default {
  namespaced: true,
  state: {
    user_selected: null,
    users: [],
    logs: [],
    logs_pagination: {
      page: 1,
      limit: 20,
      rows: 0
    },
  },
  mutations: {
    setUsers: (state, payload) => {
      state.users = payload
    },
    setUserSelected: (state, payload) => {
      state.user_selected = payload
    },
    setLogs: (state, payload) => {
      state.logs = payload
    },
    setPagination: (state, payload) => {
      state.logs_pagination = payload
    }
  },
  getters: {
    getUsers: state => {
      return state.users
    },
    getUserSelected: state => {
      return state.user_selected
    },
    getLogs: state => {
      return state.logs
    },
    getPagination: state => {
      return state.logs_pagination
    }
  },
  actions: {
    fetchUsers({commit, state}) {
      get("crm-sales/users", null, true).then(({data}) => {
        const users = calculate_averages(data.users)
        commit("setUsers", users)
        if (state.user_selected) {
          const user = users.filter(x => x.id === state.user_selected.id)[0]
          commit("setUserSelected", user)
        }
      })
    },
    fetchCreateSalesTarget({commit, state}, payload) {
      post("crm-sales/create-sales-target", payload, true).then()
    },
    fetchCreateMeet({commit, state}, payload) {
      post("crm-sales/create-sales-meet", payload, true).then()
    },
    fetchDeleteMeet({commit, state}, payload) {
      post("crm-sales/delete-sales-meet", payload, true).then()
    },
    fetchLogs({commit, state}, payload) {
      get(`crm-sales/users-logs?page=${state.logs_pagination.page}&limit=${state.logs_pagination.limit}`, payload, true).then(({data}) => {
        commit("setLogs", data.data)
        commit("setPagination", {
          ...state.logs_pagination,
          rows: data.count
        })
      })
    },
    fetchCreateCustomLog({commit, state}, payload) {
      post("crm-sales/create-custom-log", payload, true).then()
    }
  }
}

function calculate_averages(users) {

  const search_next_meet = (array_meets) => {
    if (array_meets.length === 0) {
      return {appointments: [], last_appointment: null}
    }
    const dates_after = array_meets.filter(x => moment(x.date_meet).isSameOrAfter(moment()))
    const sortedArray = dates_after.sort((a, b) => moment(a.date_meet).valueOf() - moment(b.date_meet).valueOf())
    const last_appointment = sortedArray.length ? {
      date: moment(dates_after[0].date_meet).format("Y-MM-D HH:mm"),
      description: dates_after[0].description
    } : null
    return {appointments: array_meets, last_appointment}
  }
  const feedback_average = (array_feedbacks) => {
    const sum = array_feedbacks.length ? array_feedbacks.reduce((prev, curr) => prev + curr.calification, 0) : 0
    return array_feedbacks.length ? sum / array_feedbacks.length : 0
  }
  const filterStars = (array_feedbacks) => {
    const obj = {5: 0, 4: 0, 3: 0, 2: 0, 1: 0};
    [5, 4, 3, 2, 1].forEach(item => {
      const temp = array_feedbacks.filter(x => x.calification === item)
      obj[item] = temp.length
    })
    return obj
  }

  for (const user of users) {
    user.feedback_average = feedback_average(user.feedbacks)
    user.feedback_ranking = filterStars(user.feedbacks)
    user.feedback_total = user.feedbacks.length
    const appointments = search_next_meet(user.appointments);
    user.last_appointment = appointments.last_appointment
    user.appointments = appointments.appointments
    const target = calculate_percentage_target(user)
    user.target_status = target
    user.target_color = get_color_target(target)
  }
  return users
}

function calculate_percentage_target(user) {
  const percentage = user.balance && user.targets ? (user.balance.bought * 100) / user.targets.credits_to_buy : 0
  return percentage.toFixed(2)
}

function get_color_target(value){
  return value >= 110 ? "green" : value <= 90 ? "red" : ""
}

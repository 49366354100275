import Vue from 'vue';

export default Vue.extend({
  name: 'ManuHeader',
  props: {
    showLogo: {
      default: false
    }
  },
  components: {
    LanguageManager: () => import('@/components/LanguageManager/index.vue'),
  },
});

import axios from 'axios';
import {getDataStorage} from '@/helpers/localStorage';
import router from '@/router';

const isProd = process.env.NODE_ENV === 'production';
const apiUrl = isProd ? window.location.origin + '/api/' : 'http://localhost:3000/api/';

let header = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const cancellation = {};

const manegeCancellation = (endpoint) => {
  if (cancellation[endpoint] && !cancellation[endpoint].aborted) {
    cancellation[endpoint].abort();
  }
  cancellation[endpoint] = new AbortController();
  return cancellation[endpoint].signal;
}

axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response) {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.clear();
      if (window.location.pathname !== '/admin/login') {
        router.push({name: 'login'}).then().catch();
      }
    }
    if (error.response.status === 404) {
      router.push({name: 'error'}).then().catch();
    }
  }
  return Promise.reject(error);
});

function jsonToURLEncoded(jsonString) {
  return Object.keys(jsonString)
    .map(key => (`${encodeURIComponent(key)}=${encodeURIComponent(jsonString[key])}`))
    .join('&');
}

const get = async function (endpoint, params, token = false, responseType = 'json') {
  return new Promise(async (resolve) => {
    if (token) {
      const token = getDataStorage('admin-token');
      header = {Authorization: 'Bearer '.concat(token)};
    }
    if (params) {
      resolve(axios.get(`${apiUrl + endpoint}/${params}`, {
        headers: header, responseType,
        signal: manegeCancellation(endpoint)
      }));
    } else {
      resolve(axios.get(apiUrl + endpoint, {headers: header, responseType}));
    }
  });
};

const post = async function (endpoint, params, thereIsToken, responseType = 'json') {
  if (thereIsToken) {
    const token = getDataStorage('admin-token');
    header = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  }
  if (params) {
    return axios.post(apiUrl + endpoint, params, {headers: header, responseType});
  } else {
    return axios.post(apiUrl + endpoint, {}, {headers: header, responseType});
  }
};

const postBuffer = async function (endpoint, params, thereIsToken) {
  if (thereIsToken) {
    const token = getDataStorage('admin-token');
    header = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  }
  if (params) {
    return axios.post(apiUrl + endpoint, params, {headers: header, responseType: 'blob'});
  } else
    return axios.post(apiUrl + endpoint, '', {headers: header});
};

const put = function (endpoint, params, token = false) {
  return new Promise(async (resolve) => {
    if (token) {
      const token = getDataStorage('admin-token');
      header = {Authorization: 'Bearer '.concat(token)};
    }
    axios
      .put(apiUrl + endpoint, params, {headers: header})
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {

      });
  });
};

const deleted = function (endpoint, thereIsToken = false) {
  if (thereIsToken) {
    const token = getDataStorage('admin-token');
    header = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  }
  return axios.delete(apiUrl + endpoint, {headers: header});
};
export {post, postBuffer, get, put, deleted, apiUrl};

import {get, post} from '@/services/api';
import {counterMenuData, orderSoftware} from '@/services/endpoint.json';
import {getUrgencyName} from "@/utils";

export default {
  namespaced: true,
  state: {
    data: {
      totalNewOrder: 0,
      totalNewCustomOrder: 0,
      totalNewUser: 0,
      totalNewTicket: 0,
      totalNewProduct: 0,
      totalNewPaymentClientConfirm: 0,
      totalNewVehicle: 0,
      totalOrderWaitingResponse: 0
    },
  },
  mutations: {
    setDataCounter: (state, payload) => {
      state.data = payload;
    },
    setOrdersDashboard: (state, payload) => {
      state.orders = payload;
    },
  },
  getters: {
    getDataCounter: state => {
      return state.data;
    },
    getOrdersDashboard: state => {
      return state.orders;
    },
  },
  actions: {
    async loadCounterData({commit}) {
      return new Promise(resolve => {
        post(counterMenuData, null, true)
          .then(({data}) => {
            commit('setDataCounter', data);
            resolve(data);
          });
      });
    },
  }
};

import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import {extend, localize, ValidationObserver, ValidationProvider} from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';
import CKEditor from 'ckeditor4-vue';
import VueTippy, {TippyComponent} from 'vue-tippy';
import Snotify from 'vue-snotify';


// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import 'currency-flags/dist/currency-flags.min.css';
// Styles
import 'bootstrap/dist/css/bootstrap.css';
import '@/assets/shards-dashboard-pro/shards-dashboards.scss';
import '@/assets/scss/date-range.scss';
import "vue-snotify/styles/simple.css";
import '@/assets/scss/style.scss';
// Core
import App from './App.vue';
import i18n from './i18n';
import store from './store';
import router from './router';
import Auth from '@/layouts/Auth.vue';
import Login from '@/layouts/Login.vue';

import HeaderNavigation from '@/layouts/HeaderNavigation.vue';
import IconSidebar from '@/layouts/IconSidebar.vue';
import {BootstrapVue} from 'bootstrap-vue';
import './validation.js';
import './cool-lightbox.js';

import VueSocketIO from 'vue-socket.io';
import {userCan} from '@/utils';
import VueNativeNotification from 'vue-native-notification';
import Blank from '@/layouts/Blank';
import './filters'
import {checkSubUserPermission, permission} from "@/utils/prototypes";

// Layouts
const Default = () => import('@/layouts/Default.vue');

const isProd = process.env.NODE_ENV === 'production';
Vue.use(VueTippy);
Vue.component('tippy', TippyComponent);
Vue.component('default-layout', Default);
Vue.component('auth-layout', Auth);
Vue.component('login-layout', Login);
Vue.component('blank-layout', Blank);
Vue.component('header-navigation-layout', HeaderNavigation);
Vue.component('icon-sidebar-layout', IconSidebar);
if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i).test(navigator.userAgent)) {
  Vue.use(VueNativeNotification, {
    requestOnNotify: false,
  });
  Vue.notification.requestPermission().then()
}
Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();

Vue.use(VueSweetalert2);
// Vue.use(require('vue-moment'));
Vue.use(new VueSocketIO({
  debug: false,
  connection: isProd ? window.location.origin : 'http://localhost:3000',
  options: {
    autoConnect: false,
    transports: ['websocket'],
  }
}));
Vue.use(BootstrapVue);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

// install rules and localization
Object.keys(rules)
  .forEach(rule => {
    extend(rule, rules[rule]);
  });

localize('en', en);
// ignore message specific
const ignoredMessage = 'The .native modifier for v-on is only valid on components but it was used on <a>.';
Vue.config.warnHandler = (message, vm, componentTrace) => {
  if (message !== ignoredMessage) {
  }
};
Vue.prototype.$userCan = role => userCan(role);
Vue.prototype.$userPermission = (primaryOption, moduleName, value) => permission(primaryOption, moduleName, value);
Vue.prototype.$checkSubUserPermission = (process) => checkSubUserPermission(process);

Vue.use(CKEditor);
Vue.use(Snotify);


new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
  mounted() {
    this.$store.dispatch('loadUser');
    this.$store.dispatch('subUserPermission/load')
  }
}).$mount('#app').$root.isProd = isProd;

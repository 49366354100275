import {get} from "@/services/api";
import {randomPastelColor} from "@/utils/colors";

const data_set_structure = (char = 'horizontalBar') => ([
  {
    label: 'Balance',
    data: [],
    borderColor: char === 'horizontalBar' ? "#17c671" : [],
    backgroundColor: char === 'horizontalBar' ? "#17c671" : [],
    fill: false,
  },
  {
    label: 'Pending',
    data: [],
    borderColor: char === 'horizontalBar' ? "#ffb400" : [],
    backgroundColor: char === 'horizontalBar' ? "#ffb400" : [],
    fill: false,
  },
  {
    label: 'Days left',
    data: [],
    borderColor: char === 'horizontalBar' ? "#ff0000" : [],
    backgroundColor: char === 'horizontalBar' ? "#ff0000" : [],
    fill: false,
  },
])
export default {
  namespaced: true,
  state: {
    users: [],
    users_selected: [],
    predicted_graphic: {
      type: 'horizontalBar',
      labels: [],
      original_labels: [],
      data: [{dataset: [], user_id: null}],
    },
    params: {
      time: 'month',
      from_previous: null,
      to_previous: null,
      from_current: null,
      to_current: null,
      compare: 'week',
      graphic: 'horizontalBar'
    }
  },
  mutations: {
    setUsers: (state, payload) => {
      state.users = payload
    },
    setParams: (state, payload) => {
      state.params = payload
    },
    setUserSelected: (state, payload) => {
      state.users_selected = payload
    },
    setGraphicType: (state, payload) => {
      state.predicted_graphic.type = payload
    }
  },
  getters: {
    getUsers: state => {
      return state.users
    },
    predicted_graphic: state => {
      return processData(state.users, state.users_selected, state.predicted_graphic.type)
    },
    predicted_graphic_type: state => {
      return state.predicted_graphic.type
    }
  },
  actions: {
    fetchUsers({state, commit}) {
      get(`reports/credit-predicted?time=${state.params.time}&from_previous=${state.params.from_previous}&to_current=${state.params.to_current}`).then(({data}) => {
        commit("setUsers", data)
      })
    }
  }
}

function processData(users, ids = [], char_type = 'horizontalBar') {
  const labels = [];
  const data_sets = data_set_structure(char_type)
  const setData = (item) => {
    const color = randomPastelColor()
    item.colors = {
      backgroundColor: color, borderColor: color, pointBackgroundColor: color, pointHoverBackgroundColor: color,
    }
    data_sets[0].data.push(Number((item.balance).toFixed(0)))
    data_sets[1].data.push(Number((item.pending_price).toFixed(0)))
    data_sets[2].data.push(item.time)
    if (char_type === 'pie') {
      data_sets[0].backgroundColor.push(item.colors.backgroundColor)
      data_sets[1].backgroundColor.push(item.colors.backgroundColor)
      data_sets[2].backgroundColor.push(item.colors.backgroundColor)
    }
    labels.push(item.username)
  }

  users.forEach(user => {
    if (!ids.length || ids.includes(user.id))
      setData(user)
  })
  return {data: data_sets, labels}
}

import {get, post} from '@/services/api';

export default {
  namespaced: true,
  state: {
    listPermission: [],
    permissionSelected: null,
    listAccounts: [],
    arrayRoles: [
      {value: null, text: 'Please select an option'},
      {value: 'sub_user_admin', text: 'Administrator'},
      {value: 'sub_user_support', text: 'Support'},
    ],
    subUserPermissions: []
  },
  mutations: {
    setListPermission: (state, payload) => {
      state.listPermission = payload;
    },
    setListAccounts: (state, payload) => {
      state.listAccounts = payload;
    },
    setPermissionSelected: (state, payload) => {
      state.permissionSelected = payload;
    },
    setSubUserPermissions (state, payload){
      state.subUserPermissions = payload;
      localStorage.setItem('subUserPermissions', JSON.stringify(state.subUserPermissions))
    }
  },
  getters: {
    getListPermissions: state => {
      return state.listPermission;
    },

    getTranslateRoles: (state) => (type) => {
      const roles = [
        {label: "sub_user_admin", value: "ADMINISTRATOR"},
        {label: "sub_user_support", value: "SUPPORT"},
      ]
      return roles.find(x => x.label === type).value
    }
  },
  actions: {
    async httpListPermissions({commit}, userId) {
      return new Promise(resolve => {
        get(`user-sub-permissions/view/${userId}`, null, true)
          .then(({data}) => {

            commit('setListPermission', (data)?data:[])
            resolve(data)
          })
      });
    },
    async httpSavePermissions({commit}, body) {
      return new Promise(resolve => {
        post(`user-sub-permissions/create`, body, true)
          .then(({data}) => {
            resolve(data)
          })
      });
    },
    async httpUpdatePermissions({commit}, body) {
      return new Promise(resolve => {
        post(`user-sub-permissions/update`, body, true)
          .then(({data}) => {
            resolve(data)
          })
      });
    },
    async httpDeletePermissions({commit}, body) {
      return new Promise(resolve => {
        post(`user-sub-permissions/delete`, body, true)
          .then(({data}) => {
            resolve(data)
          })
      });
    },
    async findOneListPermissions({state, commit}, permissionId) {
      const selected = state.listPermission.find(x => x._id == permissionId)
      commit("setPermissionSelected", selected)
    },

    async httpCreateSubUser({commit}, body) {
      return new Promise(resolve => {
        post("user-sub-permissions/create-user", body, true)
          .then(({data}) => {
            resolve(data)
          })
      })
    },
    async httpGetListAccounts({commit}, userId) {
      return new Promise(resolve => {
        get("user-sub-permissions/find-accounts/" + userId, null, true)
          .then(({data}) => {
            commit("setListAccounts", data)
            resolve(data)
          })
      })
    },
    async httpDeleteUserAccount({commit}, id) {
      return new Promise(resolve => {
        post(`user-sub-permissions/delete-account`, {id: id}, true)
          .then(({data}) => {
            resolve(data)
          })
      });
    },
    load({commit}){
      const data = JSON.parse(localStorage.getItem('subUserPermissions'));
      if (data){
        commit('setSubUserPermissions', data)
      }
    }
  }
};

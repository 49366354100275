export const KEYS_LOCALSTORAGE = {
  LANGUAGE: 'language',
};

export const ORDER_STATUS = () => ([
  {
    value: 'New',
    text: 'New',
    color: '#17c671',
    showInSelect: true,
  },
  {
    value: 'Waiting for response',
    text: 'Response required',
    color: '#0099cc',
    showInSelect: true,
  },
  {
    value: 'Waiting on feedback',
    text: 'Tune Supplied',
    color: '#3300cc',
    showInSelect: true,
  },
  {
    value: 'In Process',
    text: 'In progress',
    color: '#ffa700',
    showInSelect: true,
  },
  {
    value: 'Completed',
    text: 'Completed',
    color: '#232323',
    showInSelect: true,
  },
  {
    value: 'Cancel',
    text: 'Cancelled',
    color: '#d14343',
    showInSelect: true,
  },
  {
    value: 'Warranty',
    text: 'Warranty',
    color: '#cc00cc',
    showInSelect: true,
  },
  {
    value: 'Rejected',
    text: 'Rejected',
    color: '#4b0082',
    showInSelect: true,
  },
  {
    value: 'Datalogging',
    text: 'Datalogging',
    color: '#30afab',
    showInSelect: false,
  },
  {
    value: 'TVS verification',
    text: 'TVS verification',
    color: '#af8b30',
    showInSelect: true,
  },
  {
    value: 'Transfer',
    text: 'Transfer',
    color: '#D35400',
    showInSelect: false,
  },
]);

export const countriesAlphaCode = () => ([
  "AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "ML", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"
])

export const AGREEMENT_STATUS = () => ([
  {
    value: 'Completed',
    text: 'Completed',
    color: '#17c671'
  },
  {
    value: 'Incomplete',
    text: 'Incomplete',
    color: '#d14343'
  },
]);

export const USER_STATUS = () => ([
  {
    value: 'Active',
    text: 'Active',
    color: '#17c671'
  },
  {
    value: 'Inactive',
    text: 'Inactive',
    color: '#d14343'
  },
]);

export const ORDER_CREDIT_STATUS = () => ([
  {
    value: 'Offer',
    text: 'Offer',
    color: '#cc00cc'
  },
  {
    value: 'New',
    text: 'New',
    color: '#0099cc'
  },
  {
    value: 'Payment confirmed customer',
    text: 'Payment confirmed customer',
    color: '#3300cc'
  },
  {
    value: 'Payment confirmed',
    text: 'Payment confirmed',
    color: '#17c671'
  },
  {
    value: 'Partially paid',
    text: 'Partially paid',
    color: '#ffb400'
  },
  {
    value: 'Cancelled',
    text: 'Cancelled',
    color: '#d14343'
  },
]);

export const TICKET_STATUS = [
  {
    value: 'NEW',
    text: 'NEW',
    color: '#17c671'
  },
  {
    value: 'In process',
    text: 'In process',
    color: '#ffb400'
  },
  {
    value: 'Closed',
    text: 'Warranty',
    color: '#000000'
  },

];

export const OVERVIEW_CATEGORY = [
  {
    value: 'CreditTransferEntity',
    text: 'Credits',
  },
  {
    value: 'OrderRefundDifference',
    text: 'Software',
  },
  {
    value: 'CreditOrderEntity',
    text: 'Credits',
  },
  {
    value: 'CreditReFundEntity',
    text: 'Credits',
  },
  {
    value: 'CreditDeductedEntity',
    text: 'Credits',
  },
  {
    value: 'OrderSoftwareEntity',
    text: 'Software',
  },
  {
    value: 'OrderSoftwarePersonalFlasher',
    text: 'Software',
  },
  {
    value: 'RefundEntity',
    text: 'Software',
  },
  {
    value: 'WarrantEntity',
    text: 'Software',
  },
  {
    value: 'PrevOrderRefundEntity',
    text: 'Software',
  },
  {
    value: 'OrderDistributorProfit',
    text: 'Profit',
  },
];

export const CREDITS_COLORS = {positive: '#17c671', negative: '#d14343', zero: '#000000'}

export const URGENCY_NAMES = [
  {
    value: '*',
    text: '1 week',
  },
  {
    value: '**',
    text: '2 days',
  },
  {
    value: '***',
    text: '1 day',
  },
  {
    value: '****',
    text: '4 hours',
  },
  {
    value: '*****',
    text: '2 hours',
  },
  {
    value: '******',
    text: '1 hour',
  }
];

export const CREDIT_ORDERS_STATUS = {
  Offer: 'Offer',
  New: 'New',
  Payment_Confirmed_Customer: 'Payment confirmed customer',
  Payment_Confirmed_Admin: 'Payment confirmed',
  Partially_Paid: 'Partially paid',
  Cancelled: 'Cancelled',
}

export const INVOICE_INFORMATION_DEFUALT = {
  title: 'TVS Group International S.A.',
  subtitle: 'GROUP INTERNATIONAL',
  street: 'Avenida Samuel Lewis Y Calle 54E',
  zipcode: '050022',
  city: 'Panama-City',
  country: 'Panama',
  nit: '155706457-2-2021 DV 71',
  taxnr: '155706457-2-2021 DV 71',
  resolutionNr: '18760412178801',
  email: 'info@tvsgroup.nl',
  quotationPrefix: 'OCR-',
  accountHolder: 'TVS LATAM SAS',
  account: 'BE62 9672 5949 4261',
  swiftCode: 'TRWIBEB1XXX',
  address: 'Belgium',
  resolution: 'TVS-group (international) is a private organization with over 8 entities, owned by R.Logmans, the\n' +
    '        original founder&owner of TVS-engineering in the Netherlands. R.Logmans is sole shareholder of all\n' +
    '        individual entities in the TVS-group, worldwide. TVS LATAM S.A.S is a service supplier belonging\n' +
    '        to the TVS-group and one of the authorized resellers of TVS credits and related software from the\n' +
    '        TVS-group. R.Logmans is sole holder of all IP (Intellectual property) rights within the TVS-group.\n' +
    '        By paying this invoice for software services and –credits, the client declares a contractual\n' +
    '        agreement between the client(buyer) and R.Logmans(IP supplier), independent of the reselling\n' +
    '        entity within the TVS-group.',
}

export const MODULES_PLATFORM = () => ([
  {
    value: 'sale',
    label: 'SALE CREDITS',
    status: true,
    process: [
      {label: "Configuration invoice/Credit price list", value: "configuration", status: false},
      {label: "Send credit offer", value: "send_offer", status: true},
      {label: "Transfer credits", value: "transfer_credits", status: true},
      {label: "Deduct credits", value: "deduct_credits", status: true},
    ]
  },
  {
    value: 'purchase',
    label: 'PURCHASE CREDITS',
    status: true,
    process: [
      {label: "Buy credits", value: "buy_credits", status: true}
    ]
  },
])

export const MODULES_PLATFORM_CHILDREN = () => ([
  {
    value: 'sale',
    label: 'SALE CREDITS',
    status: true,
    process: [
      {label: "Configuration invoice/Credit price list", value: "configuration", status: true},
      {label: "Send credit offer", value: "send_offer", status: true},
      {label: "Transfer credits", value: "transfer_credits", status: true},
      {label: "Deduct credits", value: "deduct_credits", status: true},
    ]
  },
  {
    value: 'purchase',
    label: 'PURCHASE CREDITS',
    status: false,
    process: [
      {label: "Buy credits", value: "buy_credits", status: false}
    ]
  },
])

export const COMPLETED_MODULES_PLATFORM = () => ([
  {
    label: 'Profile',
    value: 'profile',
    status: false,
    process: []
  },
  {
    label: 'User Management',
    value: 'user_management',
    status: true,
    process: [
      {label: "Dealer management", value: "dealer-management", status: true, process: []},
      {label: "Dealer management / View Detail / Edit user’s data", value: "view-detail", status: true, process: []},
      {label: "User management", value: "account-management", status: true, process: []},
      {label: "User management / create role", value: "create-rol", status: true, process: []},
      {label: "User management / delete role", value: "delete-rol", status: true, process: []},
      {label: "User management / update role", value: "update-rol", status: true, process: []},
      {label: "User management / create user", value: "create-user", status: true, process: []},
      {label: "User management / delete user", value: "delete-user", status: true, process: []},
      {label: "User management / update password", value: "update-password", status: true, process: []},
    ]
  },
  {
    label: 'Dashboard',
    value: 'dashboard',
    status: true,
    process: [
      {label: "View Detail", value: "view-detail", status: true, process: []},
    ]
  },
  {
    label: 'Order Software',
    value: 'order_software',
    status: true,
    process: [
      {label: "Create Order", value: "create-order", status: true, process: []},
    ]
  },
  {
    label: 'Orders parts',
    value: 'order_parts',
    status: true,
    process: []
  },
  {
    label: 'Track my Order',
    value: 'track_order',
    status: true,
    process: []
  },
  {
    label: 'Credits',
    value: 'credits',
    status: true,
    process: [
      {
        value: 'sale_credits',
        label: 'Sale Credits',
        status: true,
        process: [
          {label: "Configuration invoice/Credit price list", value: "configuration", status: true},
          {label: "Send credit offer and confirm payment", value: "send_offer", status: true},
          {label: "Transfer credits", value: "transfer_credits", status: true},
          {label: "Deduct credits", value: "deduct_credits", status: true},
        ]
      },
      {
        value: 'purchase_credits',
        label: 'Purchase credits/Accept offers',
        status: true,
        process: [
          {label: "Buy credits", value: "buy_credits", status: true}
        ]
      },
    ]
  },
  {
    label: 'Support',
    value: 'support',
    status: true,
    process: []
  },
  {
    label: 'Coupon',
    value: 'coupon',
    status: true,
    process: []
  },
  {
    label: 'Downloads',
    value: 'downloads',
    status: true,
    process: [
      {label: "Uploads files", value: "uploads_files", status: true},
      {label: "Downloads files", value: "downloads_files", status: true},
    ]
  },
  {
    label: 'Feedbacks',
    value: 'feedbacks',
    status: true,
    process: []
  },
  {
    label: 'News',
    value: 'news',
    status: true,
    process: []
  },
])

export const USER_PERMISSION = {
  SALE: "sale",
  PURCHASE: "purchase",
  CONFIGURATION: "configuration",
  SEND_OFFER: "send_offer",
  TRANSFER_CREDITS: "transfer_credits",
  DEDUCT_CREDITS: "deduct_credits",
  BUY_CREDITS: "buy_credits"
}

export const FILE_TXT_FINDER = {
  ecu: {
    'VIN': ['vehicle identification number'],
    'hardware_number': ['vehicle manufacturer ecu hardware number'],
    'software_version': ['vehicle Manufacturer ecu software version Number']
  },
  tcu: {
    'VIN': ['Vehicle Identification Number'],
    'hardware_number': ['vehicle manufacturer spare part number', 'vehicle manufacturer ecu hardware number'],
    'software_version': ['vehicle Manufacturer ecu software version number'],
  }
}

export const FILE_OPTIONS_DEFINITION = () => {
  return [
    {title: 'PRE-INCLUDED MODIFICATIONS', field: 'hardwareMandatory', values: []},
    {title: 'ADDITIONAL MODIFICATIONS', field: 'hardware', values: []},
    {title: 'FEATURES', field: 'sofware', values: []},
    {title: 'SELECTABLE OPTIONS', field: 'sofwareMandatory', values: []},

    {title: 'PRE-INCLUDED MODIFICATIONS', field: 'preSelectedDsgOptions', values: []},
    {title: 'SELECTABLE OPTIONS', field: 'dsgOptions', values: []},
    {title: 'FEATURES', field: 'dsgHardwareOptions', values: []},
    {title: 'ADDITIONAL MODIFICATIONS', field: 'dsgHardwareXoptions', values: []},
  ]
}

export const creditOrderFinalConfirmationModal = (credits, price, symbol) => {
  return '</div>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '<div class="text-center col table-responsive" style="width: 100%;">\n' +
    '<table class="table table-bordered">\n' +
    '<thead>\n' +
    '<tr>\n' +
    '<th>Description</th>\n' +
    '<th>Quantity</th>\n' +
    '<th>Price</th>\n' +
    '</tr>\n' +
    '</thead>\n' +
    '<tbody>\n' +
    '<tr>\n' +
    '<td>TVS Credits</td>\n' +
    '<td> <strong><img src="' + symbol + '" style="width: 25px; padding-bottom: 5px" alt=""/>\n' +
    `${credits}` +
    '</strong>' +
    '</td>\n' +
    '<td><span style="color: #d14343 !important">' +
    `${price}` +
    '</span></td>' +
    '</tr>\n' +
    '</tbody>\n' +
    '<tfoot style="background: black; color: white">\n' +
    '<tr>' +
    `<td colspan="3">TOTAL PRICE (0% VAT): ${price}</td>` +
    '</tr>' +
    '</tfoot>\n' +
    '</table>\n' +
    '</div>\n' +
    '</div>\n'
}

export const creditOrderFinalConfirmationModalSection2 = () => {
  return '<div class="text-center">\n' +
    ` <div style="display:flex; justify-content: center" class="mt-2">
                  <checkbox> By clicking order I agree to the</checkbox>
                  <a href="https://tvs-admin.s3.us-west-2.amazonaws.com/TVS+terms+and+condition.pdf" target="_blank"  style="cursor: pointer; font-weight: bold; margin:0 0.2vw"> Terms </a>
                  <span> & </span>
                  <a href="https://tvs-admin.s3.us-west-2.amazonaws.com/TVS+terms+and+condition.pdf" target="_blank" style="cursor: pointer; font-weight: bold; margin:0 0.2vw"> Conditions </a>
                </div>` +
    '</div>'
}

export const reports_array_stages = () => [
  {
    key: 'ECU', value: [
      {key: '1', value: 'Stage 1'},
      {key: '2', value: 'Stage 2'},
      {key: '2.1', value: 'Stage 2+'},
      {key: '3', value: 'Stage 3'},
      {key: '4', value: 'Stage 4'},
    ],
  },
  {
    key: 'DSG', value: [
      {key: 'stock', value: 'Stock'},
      {key: 'original_file', value: 'Original File'},
      {key: 'drivability', value: 'Drivability'},
      {key: '1', value: 'Stage 1'},
      {key: '2', value: 'Stage 2'},
      {key: '2.1', value: 'Stage 2+'},
      {key: '3', value: 'Stage 3'},
      {key: '4', value: 'Stage 4'},
    ],
  },
  {
    key: 'ECU + DSG', value: [
      {key: '1-1', value: 'DSG 1 ECU 1'},
      {key: '2-2', value: 'DSG 2 ECU 2'},
      {key: '2-2.1', value: 'DSG 2 ECU 2+'},
      {key: '2.1-2.1', value: 'DSG 2+ ECU 2+'},
      {key: '2-3', value: 'DSG 2 ECU 3'},
      {key: '2.1-3', value: 'DSG 2+ ECU 3'},
      {key: '2.1-4', value: 'DSG 2+ ECU 4'},
      {key: '3-3', value: 'DSG 3 ECU 3'},
      {key: '3-4', value: 'DSG 3 ECU 4'},
      {key: '4-3', value: 'DSG 4 ECU 3'},
      {key: '4-4', value: 'DSG 4 ECU 4'},
    ],
  }
]

export const reports_array_dsg = () => ([
  {key: "DQ200", value: "DQ200"},
  {key: "DQ250", value: "DQ250"},
  {key: "DQ380", value: "DQ380"},
  {key: "DQ381", value: "DQ381"},
  {key: "DQ400", value: "DQ400"},
  {key: "DQ500", value: "DQ500"},
  {key: "DL382", value: "DL382"},
  {key: "DL501", value: "DL501"},
  {key: "Multitronic", value: "Multitronic"},
  {key: "Automatic", value: "Automatic"},
  {key: "DL800", value: "DL800"},
])

export const onRequestPrice = 9999


export const invoicesTitlesDistributors = () => ({
  title1: 'ORDER CONFIRMATION',
  title2: 'ORDER',
  title3: 'Ordered By',
  title4: 'Sold By',
})
export const invoicesTitlesTvs = () => ({
  title1: 'INVOICE',
  title2: 'Invoice',
  title3: 'Invoice to',
  title4: 'Payment info',
})

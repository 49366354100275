<template>
  <div class="main-navbar__search w-100 d-none d-md-flex d-lg-flex justify-content-between">
    <b-navbar-nav class="flex-row justify-content-center hidden-xs">
      <div v-if="!$userCan(['administrator','support','calibrator'])" class="centrar" @click="openModalCalendar()"
           style="cursor: pointer">
        <span :class="supportStatus.textClassRed?'text-danger heart':''">UPCOMING CLOSED DATES: {{ supportStatus.nextDay }} <i class="fa fa-caret-down"></i></span>
      </div>
      <div
        v-if="!$userCan(['administrator','support','calibrator'])" class="centrar"
        :style="{'border-bottom': `${supportStatus.isOpen? 'rgb(23, 198, 113)' : '#d14343'} solid 0.3em`}">
        <span>SUPPORT {{ supportStatus.isOpen ? 'OPEN' : 'CLOSE' }}</span>
      </div>
      <div
        v-if="!$userCan(['administrator','support','calibrator'])" class="centrar">
        <span>{{ supportStatus.isOpen ? 'REMAINING OPEN TIME' : 'REMAINING TIME TO OPEN' }}: {{
            supportStatus.hours
          }}:{{ supportStatus.minutes }}</span>
      </div>
    </b-navbar-nav>
    <b-img v-if="$userCan(['distributor', 'dealer', 'subdealer'])" class="logo hidden-xs"
           :src="require(`@/assets/images/logo_2.svg`)" style="width: 17% !important;"/>
    <b-navbar-nav class="flex-row justify-content-end">
      <template v-if="$userCan(['distributor', 'dealer', 'subdealer']) ">
        <li class="nav-item m-auto px-3" style="font-size: 1rem" v-if="information.balance>1000">
          BALANCE:
          <currency-price-view :amount="information.balance" class="font-weight-bold"/>
        </li>
        <li v-else class="nav-item m-auto px-3" style="font-size: 1rem">
          <div class="text-danger">
            <span class="bolder"> BALANCE:
               <currency-price-view :amount="information.balance" class="font-weight-bold"/>
            </span>
            <i class="material-icons text-danger temblor">notifications_active</i>
          </div>
        </li>
      </template>
      <li v-if="showLi && $store.state.geo.showSwitch" class="nav-item border-left"
          v-tippy="{ placement : 'top',  arrow: true }" :content="$store.state.geo.showInLocal?`credits and prices are being shown in your local currency`: `credits and prices are being shown in euros and tvs credits`">
        <div class="nav-link nav-link-icon text-center d-flex justify-content-end my-1 px-1">
          <b-checkbox size="lg" switch @change="$store.commit('geo/switchShowCredits')"
                      :checked="$store.state.geo.showInLocal"/>
        </div>
      </li>
    </b-navbar-nav>
    <b-modal id="modal-calendar-dealers" size="lg" title="UPCOMING CLOSED DATES" hide-footer>
      <b-row>
        <b-col class="col">
          <b-calendar value-as-date
                      hide-header
                      block
                      :date-info-fn="dateClass"
                      locale="en"
                      calendarLocale="en"
                      nav-button-variant="primary"
          ></b-calendar>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import CreditsView from '@/components/common/CreditsView';
import * as moment from "moment";
import {get} from "@/services/api";
import {UserRole} from "@/utils/auth.roles";
import CurrencyPriceView from "@/components/common/CurrencyPriceView.vue";

export default {
  components: {CreditsView, CurrencyPriceView},
  computed: {
    ...mapState('buySale', ['information']),
    ...mapGetters('geo', ['currencyToUser', 'creditsToLocalCredits', 'currencyCreditSymbol']),
  },
  mounted() {
    const isWeekend = this.validateWeekends()
    this.today = moment().format('YYYY-MM-DD')
    if (!isWeekend) {
      if (this.$userCan(['dealer', 'subdealer', 'distributor'])) {
        this.sockets.subscribe('support-open-close', (data) => {
          if (data) {
            this.processData(data)
          }
        });
        this.getDaysClose()
      }
    } else {
      this.supportStatus.isOpen = false
    }
    this.validateStoreEmpty()
    this.showInLocalCurrency()
  },
  data: () => ({
    lowBalance: false,
    interval: null,
    supportStatus: {
      hours: 0,
      minutes: 0,
      isOpen: false,
      nextDay: null,
      textClassRed: false
    },
    showLi: false,
    arrayDates: [],
    today: null,
    calendar: {
      dateClass: '',
    }
  }),
  methods: {
    async getDaysClose() {
      const {data} = await get('calendars/support-get-day-close', null, true)
      if (data.length) {
        this.processData(data)
      }
      this.calculate();
      this.calculateTimeSupport()
    },
    processData(data) {
      this.arrayDates = [];
      this.arrayDates = data.map(x => x.date)
      const find = this.arrayDates.includes(this.today)
      this.supportStatus.nextDay = this.arrayDates.filter(x => x > this.today)[0]
      if (find) {
        this.supportStatus.isOpen = false
      }
      this.calculate();
      this.calculateTimeSupport()
      this.validateTextDateRed()
    },
    calculate() {
      const d = new Date();
      const aa = d.toLocaleString(['en-us'], {timeZone: 'Europe/Amsterdam',});
      const localeFormatDate = moment.localeData().longDateFormat('L');
      const localeFormat = moment.localeData().longDateFormat('LTS');
      let startTime = moment(aa, `${localeFormatDate} ${localeFormat}`);
      let endTime = moment("06:00:00 pm", 'hh:mm:ss a');
      if (this.arrayDates.includes(this.today)) {
        endTime = moment();
      }
      if (endTime.diff(startTime, 'seconds') < 0) {
        if (this.validateIsDay(5)) {
          endTime = moment("08:00:00 am", 'hh:mm:ss a').add(3, 'days')
        } else if (this.validateIsDay(6)) {
          endTime = moment("08:00:00 am", 'hh:mm:ss a').add(2, 'days')
        } else {
          endTime = moment("08:00:00 am", 'hh:mm:ss a').add(1, 'days')
        }
        this.supportStatus.isOpen = false;
      } else {
        this.supportStatus.isOpen = (!this.arrayDates.includes(this.today))
      }
      const totalHours = endTime.diff(startTime, 'hours');
      const totalMinutes = endTime.diff(startTime, 'minutes');
      this.supportStatus.hours = ((totalHours % 60) <= 9) ? 0 + '' + totalHours % 60 : totalHours % 60
      this.supportStatus.minutes = ((totalMinutes % 60) <= 9) ? 0 + '' + totalMinutes % 60 : totalMinutes % 60
    },
    calculateTimeSupport() {
      this.interval = setInterval(() => {
        this.calculate()
      }, 1000 * 60)
    },
    validateWeekends() {
      const d = new Date();
      const aa = d.toLocaleString(['en-us'], {timeZone: 'Europe/Amsterdam',});
      const today = moment(aa).day()
      return (today == 0 || today == 6)
    },
    validateIsDay(day) {
      const d = new Date();
      const aa = d.toLocaleString(['en-us'], {timeZone: 'Europe/Amsterdam',});
      const today = moment(aa).day()
      return Number(today) === Number(day)
    },
    validateStoreEmpty() {
      if (this.$store.state.geo !== null) {
        this.showLi = this.$userCan(['administrator', 'calibrator', 'support']) || (this.$store.state.geo.tvsCurrency.code !== this.$store.state.geo.userCurrency.code)
      }
    },
    showInLocalCurrency() {
      const showSwitchCurrency = localStorage.getItem("showSwitchCurrency")
      const showCurrencyInLocal = localStorage.getItem("showCurrencyLocal")
      if (this.$userCan([UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer]))
        this.$store.state.geo.showSwitch = showSwitchCurrency === 'true'
      if (showCurrencyInLocal === 'true')
        this.$store.commit('geo/switchShowCredits')
    },
    openModalCalendar() {
      this.$bvModal.show("modal-calendar-dealers")
    },
    dateClass(ymd) {
      if (this.arrayDates.includes(ymd))
        return 'table-danger-date'
    },
    validateTextDateRed() {
      const today = moment().format("YYYY-MM-DD")
      const beforeDate = moment(this.supportStatus.nextDay).subtract(7, 'days').format("YYYY-MM-DD")
      this.supportStatus.textClassRed = (today >= beforeDate && today <= this.supportStatus.nextDay)
    }
  },
  destroyed() {
    clearInterval(this.interval)
  }
};
</script>

<style scoped lang="scss">
.bolder {
  font-weight: bold;
}

.support_section {
  span {
    font-weight: bolder;
  }
}

.centrar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1vw;
  margin-left: 1vw;
}

.heart {
  width: 100%;
  color: #d14343;
  font-weight: bold;
  animation: beat2 1s infinite alternate;
}

/* Heart beat animation */
@keyframes beat2 {
  to {
    text-shadow: 0 0 10px #d14343;
  }
}


.temblor {
  animation: tiembla 1s infinite alternate;
}

@-webkit-keyframes tiembla {
  0% {
    -webkit-transform: rotateZ(-5deg);
  }
  50% {
    -webkit-transform: rotateZ(0deg) scale(.8);
  }
  100% {
    -webkit-transform: rotateZ(5deg);
  }
}


@media screen and (max-width: 1024px) {
  .hidden-xs {
    display: none;
  }
}
</style>

import {extend} from 'vee-validate';
import {email, numeric, required, length, max} from 'vee-validate/dist/rules';
import {post} from '@/services/api';
import {encrypt} from '@/helpers/encrypt';


extend('required', {
  ...required,
  message: 'required',
});

extend('numeric', {
  ...numeric,
  message: 'numeric',
});

// Add the email rule
extend('email', {
  ...email,
  message: 'error_email',
});

extend('minmax', {
  validate(value, {min, max}) {
    return value.length >= min && value.length <= max;
  },
  params: ['min', 'max'],
  message: 'The {_field_} field must have at least {min} characters and {max} characters at most',
});

extend('password', {
  params: ['target'],
  validate(value, {target}) {
    return value === target;
  },
  message: 'password_not_match'
});

extend('current-password', {
  validate: async value => {
    const {data} = await post('user/validate-password', {
      password: encrypt(value)
    }, true);
    if (data) {
      return true;
    }
    return {
      valid: false,
      data: {}
    };
  },
  message: `current_password_not_match`
});

extend('vin', {
  validate: value => {
    let regex = /^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17,18}$/;
    let space = /^[a-zA-Z0-9_]*$/;
    return regex.test(value) && space.test(value);
  },
  message: 'vin_invalid'
});

extend('vin_search', {
  validate: value => {
    let regex = /^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/;
    let space = /^[a-zA-Z0-9_]*$/;
    return regex.test(value) && space.test(value);
  },
  message: 'vin_invalid_length'
});

extend('notComma', {
  validate: value => {
    let regex = /^(\d+\.?|\d*\.\d+)$/;
    return regex.test(value);
  },
  message: 'vin_invalid'
});

extend('discount', {
  params: ['target'],
  validate(value, {target}) {
    return value <= target;
  },
  message: 'The max discount is 65%' //less_than
});

extend('length', {
  ...length,
  message: 'The {_field_} field must have less than {length} characters at most',
});

extend('max', {
  ...max,
  message: 'The {_field_} field must have {length} characters at most',
})

extend('minimum_pass', {
  params: ['target'],
  validate(value) {
    const reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
    return reg.test(value)
  },
  message: 'Minimum 8 characters, at least one uppercase letter, lowercase letter and one number'
})
extend('dateformat', {
  params: ['target'],
  validate(value){
    return value.includes('DD') && value.includes('MM') && value.includes('YYYY')
  },
  message: 'Must contain these words "DD" "MM" "YYYY"'
})

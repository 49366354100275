<template>
  <component :is="layout">
    <router-view/>
    <div class="refresh-container" v-if="hashChanged">
      <div class="notification-header">
        <button type="button" class="close-refresh-modal" @click="closeModal" aria-label="Close">
          <span aria-hidden="true"><i class="material-icons">close</i></span>
        </button>
      </div>
      <div class="notification-body">
        <div class="notification-button">
          <p class="text-center font12">
            An update is available. Please save all current work and click update below. You
            can also accept these updates by refreshing your browser at any time.</p>
          <p class="text-center"><span class="font10">Not updating may result in errors.</span></p>
        </div>
        <div class="refresh-button text-center">
          <button class="btn btn-danger mt-2" @click="reloadApp">Update</button>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import {RefreshPageMixin} from '@/utils/refresh-page.mixin'
const isProd = process.env.NODE_ENV === 'production';
export default {
  data: () => ({}),
  mixins: [RefreshPageMixin],
  computed: {
    layout() {
      return `${this.$route.meta.layout || 'auth'}-layout`;
    },
  },
  mounted() {
    try {
      if (isProd){
        this.initVersionCheck('version.json', 60 * 1000)
      }
      this.sockets.subscribe('handledCheckVersion', () => {
        this.checkVersion('version.json')
      })
    }catch (e) {}
  }
};
</script>
<style lang="scss">
@import 'assets/scss/vue-tables.scss';

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.close-refresh-modal {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
  color: #FFFFFF;
  background: #0b0b0b;
}
.font12{
  font-size: 1em;
  color: #797979;
}
.refresh-container {
  width: 25em;
  position: fixed;
  top: 4.2em;
  right: .6em;
  background-color: #FFFFFF;
  padding: 25px;
  -webkit-box-shadow:0 1px 4px rgba(26, 24, 24, 0.3), 0 0 40px rgba(31, 24, 24, 0.05);
  -moz-box-shadow:0 1px 4px rgba(17, 17, 17, 0.3), 0 0 40px rgba(73, 65, 65, 0.05);
  box-shadow:0 1px 4px rgba(52, 37, 37, 0.3), 0 0 40px rgba(110, 93, 93, 0.05);
  border-radius: 10px;
  animation-name: slidein;
  animation-duration: 1.5s;
  z-index: 2500;
}
.font10{
  font-size: .9em;
  color: #646464;
}

@keyframes slidein {
  from {
    right: -25em;
  }

  to {
    right: .6em;
  }
}
</style>

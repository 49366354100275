import {isStagevisible} from "@/utils";

const stage1Info = 'TVS Stage 1 chiptuning is characterized as a mild increase in performance. TVS introduced this low budget solution to make TVS ECU software affordable for everyone!';
const stage2Info = 'TVS Stage 2 chiptuning is characterized as maximum performance out of a stock engine setup within all safety limits. For gasoline engines performance numbers are based on RON 98 fuel, but is available for different fuel qualities.';
const stage2PInfo = 'TVS Stage 2+ chiptuning is maximum performance software for a modified engine setup with stock turbo. Performance numbers may vary slightly depending on modifications and fuel grade.';
const conststage3Info = 'TVS Stage 3 chiptuning is custom made software for turbo- or supercharger upgrades! Due to our knowledge, experience and specialism in calibrating high power applications, TVS is able to deliver custom ECU software for almost every turbo- and supercharger upgrade.';
const conststage4Info = 'TVS Stage 4 chiptuning is custom made software for a big turbo- or supercharger upgrade with a heavily modified engine setup achieving performance numbers far above the factory ones!';

export const checkPrice = (price) => {
  if (price && !isNaN(price) && price > 0) {
    return price
  }
  return 'On request'
}

const processDataSoftwareTunning = (item, stage_visibility = null) => {
  const dataStages = [];
  const ecuVisibility = stage_visibility ? stage_visibility.ecu : null
  if (item.stageOneOne.length && isStagevisible('1', ecuVisibility)) {
    const tempPower = (item.stageOnePower !== null && item.stageOnePower !== '' && item.stageOnePower != 'Contact') ? item.stageOnePower : 'On request';
    const tempTorque = (item.stageOneTorque !== null && item.stageOneTorque !== '' && item.stageOneTorque != 'Contact') ? item.stageOneTorque : 'On request';

    dataStages.push({
      type: 'ecu',
      vehicleId: item.id,
      id: '1',
      level: 'STAGE ONE',
      data: stage1Info,
      options: item.stageOneOne.concat(item.stageOneTwo).map(el => ({
        ...el,
        choices: el.choices ? JSON.parse(el.choices) : null
      })),
      power: tempPower,
      price: checkPrice(item.stageOnePrice),
      torque: tempTorque,
      img: require('@/assets/images/stages/1.svg')
    });
  }
  if (item.stageTwoOne.length && item.stageTwoPrice && isStagevisible('2', ecuVisibility)) {
    const tempPower = (item.stageTwoPower !== null && item.stageTwoPower !== '' && item.stageTwoPower != 'Contact') ? item.stageTwoPower : 'On request';
    const tempTorque = (item.stageTwoTorque !== null && item.stageTwoTorque !== '' && item.stageTwoTorque != 'Contact') ? item.stageTwoTorque : 'On request';
    dataStages.push({
      type: 'ecu',
      vehicleId: item.id,
      id: '2',
      level: 'STAGE TWO',
      data: stage2Info,
      options: item.stageTwoOne.concat(item.stageTwoTwo, item.stageTwoThree).map(el => ({
        ...el,
        choices: el.choices ? JSON.parse(el.choices) : null
      })),
      power: tempPower,
      price: checkPrice(item.stageTwoPrice),
      torque: tempTorque,
      img: require('@/assets/images/stages/2.svg')
    });
  }
  if (item.stageTwoPlusOne.length && item.stageTwoPlusPrice && isStagevisible('2.1', ecuVisibility)) {
    const tempPower = (item.stageTwoPlusPower !== null && item.stageTwoPlusPower !== '' && item.stageTwoPlusPower != 'Contact') ? item.stageTwoPlusPower : 'On request';
    const tempTorque = (item.stageTwoPlusTorque !== null && item.stageTwoPlusTorque !== '' && item.stageTwoPlusTorque != 'Contact') ? item.stageTwoPlusTorque : 'On request';

    dataStages.push({
      type: 'ecu',
      vehicleId: item.id,
      id: '2.1',
      level: 'STAGE TWO +',
      data: stage2PInfo,
      options: item.stageTwoPlusOne.concat(item.stageTwoPlusTwo, item.stageTwoPlusThree, item.stageTwoPlusFour).map(el => ({
        ...el,
        choices: el.choices ? JSON.parse(el.choices) : null
      })),
      power: tempPower,
      price: checkPrice(item.stageTwoPlusPrice),
      torque: tempTorque,
      img: require('@/assets/images/stages/2P.svg')
    });
  }
  if (item.stageThreeOne.length && item.stageThreePrice && isStagevisible('3', ecuVisibility)) {
    const tempPower = (item.stageThreePower !== null && item.stageThreePower !== '' && item.stageThreePower != 'Contact') ? item.stageThreePower : 'On request';
    const tempTorque = (item.stageThreeTorque !== null && item.stageThreeTorque !== '' && item.stageThreeTorque != 'Contact') ? item.stageThreeTorque : 'On request';

    dataStages.push({
      type: 'ecu',
      vehicleId: item.id,
      id: '3',
      level: 'STAGE THREE',
      data: conststage3Info,
      options: item.stageThreeOne.concat(item.stageThreeTwo, item.stageThreeThree, item.stageThreeFour).map(el => ({
        ...el,
        choices: el.choices ? JSON.parse(el.choices) : null
      })),
      power: tempPower,
      price: checkPrice(item.stageThreePrice),
      torque: tempTorque,
      img: require('@/assets/images/stages/3.svg')
    });
  }
  if (item.stageFourOne.length && item.stageFourPrice && isStagevisible('4', ecuVisibility)) {
    const tempPower = (item.stageFourPower !== null && item.stageFourPower !== '' && item.stageFourPower != 'Contact') ? item.stageFourPower : 'On request';
    const tempTorque = (item.stageFourTorque !== null && item.stageFourTorque !== '' && item.stageFourTorque != 'Contact') ? item.stageFourTorque : 'On request';

    dataStages.push({
      type: 'ecu',
      vehicleId: item.id,
      id: '4',
      level: 'STAGE FOUR',
      data: conststage4Info,
      options: item.stageFourOne.concat(item.stageFourTwo, item.stageFourThree, item.stageFourFour).map(el => ({
        ...el,
        choices: el.choices ? JSON.parse(el.choices) : null
      })),
      power: tempPower,
      price: checkPrice(item.stageFourPrice),
      torque: tempTorque,
      img: require('@/assets/images/stages/4.svg')
    });
  }
  return (dataStages);
};

export {processDataSoftwareTunning};


import * as moment from 'moment';
import {get, post} from '@/services/api';
import {calculateDiscount, processCombos} from '@/views/dashboard/create-software-order/proccessCombos';
import {
  checkPrice,
  processDataSoftwareTunning
} from '@/views/dashboard/create-software-order/processDataSoftwareTunning';
import {processDataDSGTunning} from '@/views/dashboard/create-software-order/processDataDSGTunning';
import {filterEngine, filterGeneration, filterModel, site} from '@/services/endpoint.json';

const parse = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
  }
}
const init = () => {
  return {
    showDsgConversionAndFlex:false,
    isFileServerEnabled: false,
    file_details: {
      VIN: '',
      ecu: {
        hardware_number: null,
        software_version: null,
        tool: 'TCM', // TCM or CMD
        cmd_file_key: null,
        file: null
      },
      tcu: {
        hardware_number: null,
        software_version: null,
        file: null
      }
    },
    file_on_server: {
      ecu: {
        stock: null,
        files: []
      },
      tcu: {
        stock: null,
        files: []
      },
    },
    vin_search_result: null,
    vehicle: {
      id: null,
      brand: null,
      model: null,
      generation: null,
      engine: null,
      model_year: null,
      dsg_family: null,
      gearbox_code: null,
      engine_family: null,
      ecu_code: null,
      dsg_code: null,
      auto_found: false,
    },
    filter: {
      ecu: {
        options: [],
        stages: []
      },
      tcu: {
        options: [],
        stages: []
      }
    },
    proceedAs: null, // fileServer or custom
    dropdowns_filter: {
      brand: null,
      model: null,
      generation: null,
      engine: null,
      gearbox: null
    },
    dropdowns_values: {
      brands: [],
      models: [],
      generations: [],
      engines: [],
      gearboxes: [],
    },
    forCustomOrder: {
      ecu: [],
      combos: [],
      tcu: [],
    },
    combos: [],
    comboRules: [],
    // order
    order: {
      serverFiles: [],
      files: [],
      items: [],
      type: null,
      stage: null,
      client: null,
      ecu_info: null,
      vin_verified: null,
      urgency: '*',
      price: 0,
      fees: [], // {id, value}
      coupon: null,
      to_refund: null,
      note: null,
    },
    previous_order: {
      to_upgrade: null,
      last: null,
      completed: null,
    },
    price_policy: {
      discount_after_a_year: null,
      minimum_price: null,
      only_on_upgrade_fee: null,
    },
    stage_visibility: null,
    item_seeing_Ecu: null,
    item_seeing_Tcu:null,
    lastFileUploadedByClient: null,
  };
};

function findDsgFamilyById(data, vehicle_id, dsg) {
  let count = 0;
  let dsg_family = null;
  for (let key in data) {
    const stock = data[key].stock;
    if (stock && stock.vehicle_id === vehicle_id) {
      count++;
      if (count === 1) {
        dsg_family = stock.dsg_family;
      } else {
        return dsg;
      }
    }
  }
  return dsg_family; // Return the dsg_family or null
}
export default {
  namespaced: true,
  state: init(),
  mutations: {

    showDsgConversionAndFlex(state, value){
      state.showDsgConversionAndFlex = value
    },

    addOrderFile(state, payload) {
      state.order.files.push(payload);
    },
    addServerFile(state, payload) {
      const index = state.order.serverFiles.findIndex(file => file.file_type === payload.file_type);
      if (index > -1) {
        state.order.serverFiles.splice(index, 1, payload);
        //if custom part is added, remove the custom  part avoid  whron combo
        state.order.type = null;
        state.order.stage = null;
        state.order.price = 0;
        state.order.fees = [];
      } else {
        state.order.serverFiles.push(payload);
      }
    },
    setFilter(state, payload) {
      state.filter[payload.type] = {
        stages: payload.stages,
        options: payload.options
      };
    },
    setFileDetails(state, payload) {
      state.file_details[payload.type] = {
        ...payload.details,
        uploaded: true
      };
      state.file_details.VIN = payload.VIN ? payload.VIN.toUpperCase() : null;
    },
    setFilesOnServer(state, payload) {
      state.file_on_server[payload.type] = {
        stock: payload.stock,
        files: payload.files
      };
    },
    setVinSearchResult(state, payload) {
      state.vin_search_result = payload;
    },
    setVehicle(state, payload) {
      Object.assign(state.vehicle, payload);
    },
    setProceedAs: (state, payload) => {
      state.proceedAs = payload;
    },
    setDataForCustomOrder(state, payload) {
      state.forCustomOrder = payload;
    },
    setTCUForCustomOrder(state, payload) {
      state.forCustomOrder.tcu = payload;
    },
    setECUForCustomOrder(state, payload) {
      state.forCustomOrder.ecu = payload;
    },
    setCombos(state, payload) {
      state.combos = payload;
    },
    setOrderCustomTypeStage(state, payload) {
      state.order.type = payload.type;
      state.order.stage = payload.stage;
      state.order.price = payload.price;
      state.order.fees = [];
    },
    removeCustomPart(state) {
      state.order.type = null;
      state.order.stage = null;
      state.order.price = 0;
      state.order.fees = [];
    },
    setOrderItems(state, payload) {
      state.order.items = payload;
    },
    setOrderToCheckOut(state, payload) {
      Object.assign(state.order, payload);
    },
    setOrderPrice(state, payload) {
      state.order.price = payload;
    },
    setOrderFees(state, payload) {
      state.order.fees.push(Number(payload));
    },
    removeOrderFee(state, payload) {
      const index = state.order.fees.findIndex(fee => fee === payload);
      if (index > -1) {
        state.order.fees.splice(index, 1);
      }
    },
    setDropdownsFilter(state, payload) {
      Object.assign(state.dropdowns_filter, payload);
    },
    setDropdownValue(state, payload) {
      state.dropdowns_values[payload.key] = payload.data;
    },
    setLastOrder(state, payload) {
      if (!payload) {
        state.previous_order.last = null;
        return;
      }
      state.previous_order.last = {
        ...payload,
        vehicle: parse(payload.vehicle),
        description: parse(payload.description),
      };
    },
    setCompletedOrder(state, payload) {
      if (!payload) {
        state.previous_order.completed = null;
        return;
      }
      state.previous_order.completed = {
        ...payload,
        vehicle: parse(payload.vehicle),
        description: parse(payload.description),
      };
    },
    setToUpgradeOrder(state, payload) {
      if (!payload) {
        state.previous_order.to_upgrade = null;
        return;
      }
      state.previous_order.to_upgrade = {
        ...payload,
        vehicle: parse(payload.vehicle),
        description: parse(payload.description),
      };
    },
    setCoupon(state, payload) {
      state.order.coupon = payload;
    },
    setVinVerified(state, payload) {
      state.order.vin_verified = payload;
    },
    removeOrderServerFile(state, payload) {
      state.order.serverFiles.splice(payload, 1);
    },
    setPricePolicy(state, payload) {
      state.price_policy = payload;
    },
    setToRefund(state, payload) {
      state.order.to_refund = payload;
    },
    onProceedAsChange(state) {
      Object.assign(state.order, {
        type: null,
        stage: null,
        client: null,
        ecu_info: null,
        urgency: '*',
        price: 0,
        coupon: null,
        to_refund: null,
        serverFiles: [],
      })
    },
    setComboRules(state, payload) {
      state.comboRules = payload;
    },
    clear(state) {
      state = Object.assign(state, init());
    },
    clearOnReUploadFile(state, type) {
      state.file_on_server[type] = {
        stock: null,
        files: []
      };
      state.forCustomOrder = {
        ecu: [],
        combos: [],
        tcu: [],
      }
      state.dropdowns_filter = {
        brand: null,
        model: null,
        generation: null,
        engine: null,
        gearbox: null
      }
      state.dropdowns_values = {
        brands: [],
        models: [],
        generations: [],
        engines: [],
        gearboxes: [],
      }
      state.proceedAs = null;
      state.order = init().order;
    },
    clearOnVINchange(state) {
      state.proceedAs = null;
      state.file_details.VIN = null;
      state.vin_search_result = null;
      state.vehicle = {
        id: null,
        brand: null,
        model: null,
        generation: null,
        engine: null,
        model_year: null,
        dsg_family: null,
        gearbox_code: null,
        engine_family: null,
        ecu_code: null,
        dsg_code: null,
        auto_found: false,
      };
      state.order = init().order;
    },
    setStagevisibility(state, payload) {
      state.stage_visibility = payload;
    },
    setItemSeeingEcu(state, payload){
        state.item_seeing_Ecu = payload
    },
    setItemSeeingTcu(state, payload){
      state.item_seeing_Tcu = payload
    }
  },
  getters: {
    DsgConversionAndFlexStatus:(state)=> {
      return state.showDsgConversionAndFlex
    },

    getSumFee: state => {
      return state.order.fees.reduce((sum, fee) => sum + fee, 0);
    },
    is_combo: (state) => {
      return state.file_details.ecu.file && state.file_details.tcu.file;
    },
    hasCustomPart: (state) => {
      return state.order.type !== null;
    },
    customPart: (state, getters) => {
      const price = () => {
        if ([state.order.price, ...state.order.fees].includes(9999)) {
          return 9999;
        }
        return state.order.price + getters.getSumFee;
      }
      return {
        type: state.order.type,
        stage: state.order.stage,
        price: price(),
      };
    },
    getCombos: (state) => {
      return state.forCustomOrder.combos.length > 0 ? state.forCustomOrder.combos : state.combos;
    },
    comboFound: (state, getters) => {
      const customPart = getters.customPart;
      const fileEcu = state.order.serverFiles.find(el => el.file_type === 'ECU') ||
        (customPart.type === 'ECU' ? customPart : null) ||
        (state.previous_order.to_upgrade && state.previous_order.to_upgrade.type === 'ECU' ? state.previous_order.to_upgrade : null);

      const fileTcu = state.order.serverFiles.find(el => el.file_type === 'TCU') ||
        (customPart.type === 'DSG' ? customPart : null) ||
        (state.previous_order.to_upgrade && state.previous_order.to_upgrade.type === 'DSG' ? state.previous_order.to_upgrade : null);

      const determinePriceCombo = (combo) => {
        if (isNaN(combo.price)) return 9999;

        // let subtotal = getters.subtotal;
        let subtotal = 0;
        for (const {price} of state.order.serverFiles) subtotal += Number(price);
        subtotal += Number(state.order.price);

        if (state.previous_order.to_upgrade) {
          subtotal += state.previous_order.to_upgrade.price;
        }
        if (subtotal < Number(combo.price)) return subtotal;

        return Number(combo.price);
      }

      if (fileEcu && fileTcu) {
        const id = `${fileEcu.stage}-${fileTcu.stage}`;

        const combo = getters.getCombos.find(el => el.id === id);
        if (combo) {
          return {
            ...combo,
            price: determinePriceCombo(combo)
          };
        }
      }
      return null;
    },
    discount: (state, getters) => {
      if (getters.comboFound) {
        const total = getters.subtotal;
        if (total >= getters.comboFound.price) {
          return calculateDiscount(getters.comboFound.price, total);
        }
      }
      return 0;
    },
    subtotal: (state) => {
      if ([state.order.price, ...state.order.fees].includes(9999)) {
        return 9999;
      }
      let subtotal = 0;
      for (const {price} of state.order.serverFiles) subtotal += Number(price);
      subtotal += Number(state.order.price);
      return subtotal;
    },
    subTotalPlusFees: (state, getters) => {
      const subtotal = getters.subtotal;
      if (getters.comboFound) {
        return getters.comboFound.price;
      }
      if (subtotal === 9999) return 9999;
      return subtotal + getters.getSumFee;
    },
    total: (state, getters) => {
      if (state.order.stage === 'original_file') {
        return getters.getSumFee;
      }
      let sum = getters.subtotal;
      const combo = getters.comboFound;
      if ([sum, combo ? combo.price : 0, ...state.order.fees, state.order.price].includes(9999))
        return 9999;

      if (getters.comboFound) {
        sum = getters.comboFound.price;
      }
      if (state.order.coupon) {
        sum -= state.order.coupon.discount;
      }
      sum += state.order.fees.filter(el => el !== 9999).reduce((sum, fee) => sum + fee, 0);

      return sum;
    },
    orderType: (state, getters) => {
      if (state.order.type !== null && state.order.type.includes('+')) {
        return state.order.type;
      } else if (!state.order.serverFiles.length) {
        return state.order.type;
      }
      if (getters.comboFound) {
        return 'ECU + DSG';
      }
      const fileEcu = state.order.serverFiles.find(el => el.file_type === 'ECU');
      const fileTcu = state.order.serverFiles.find(el => el.file_type === 'TCU');

      if (fileEcu && fileTcu) {
        return 'ECU + DSG';
      }
      if (fileEcu) {
        return 'ECU';
      }

      if (fileTcu) {
        return 'DSG';
      }
    },
    orderStage: (state, getters) => {
      if (state.order.type !== null && state.order.type.includes('+')) {
        return state.order.stage;
      } else if (!state.order.serverFiles.length) {
        return state.order.stage;
      }
      if (getters.comboFound) {
        return getters.comboFound.id;
      }
      const fileEcu = state.order.serverFiles.find(el => el.file_type === 'ECU');
      const fileTcu = state.order.serverFiles.find(el => el.file_type === 'TCU');
      if (fileEcu && fileTcu) {
        return `${fileEcu.stage}-${fileTcu.stage}`;
      }

      if (fileEcu) {
        return fileEcu.stage;
      }
      if (fileTcu) {
        return fileTcu.stage;
      }
    },
    // previous order management
    applyUpgrade(state, getters) {
      if (!state.previous_order.to_upgrade) {
        return false;
      }
      if (getters.orderType.includes('+')) {
        return true;
      }
      if (getters.comboFound) {
        return true;
      }
      return state.previous_order.to_upgrade.type === getters.orderType;
    },
    filesInDetails(state) {
      const files = [];
      if (state.file_details.ecu.file) {
        files.push(state.file_details.ecu.file)
      }
      if (state.file_details.tcu.file) {
        files.push(state.file_details.tcu.file)
      }
      return files;
    },
    isVehicleSelected(state, getters) {
      return [...state.forCustomOrder.ecu, ...state.forCustomOrder.tcu, ...state.forCustomOrder.combos].length && getters.hasCustomPart;
    },
    canCreateOrder(state, getters, rootState) {
      const allow = rootState.buySale.information.allowedOrderNoBalance;
      const totalOrdered = rootState.buySale.information.totalOrderSoftwareToPay;
      const balance = rootState.buySale.information.balance;
      const total = getters.total;
      if (allow.status) {
        if (allow.limit > 0) {
          return totalOrdered < allow.limit;
        }
        return true;
      } else {
        if (total === 9999) return balance > 0;
        else return balance >= total;
      }
    },
    cannotCreateNoBalance(state, getters, rootState) {
      const allow = rootState.buySale.information.allowedOrderNoBalance;
      const totalOrdered = rootState.buySale.information.totalOrderSoftwareToPay;
      const balance = rootState.buySale.information.balance;
      const total = getters.total;
      if (!allow.status) {
        if (total === 9999) return balance <= 0;
        else return balance < total;
      }
      return false
    },
    reachedCreditLimit(state, getters, rootState) {
      const allow = rootState.buySale.information.allowedOrderNoBalance;
      const totalOrdered = rootState.buySale.information.totalOrderSoftwareToPay;
      if (allow.status) {
        if (allow.limit > 0) {
          return totalOrdered >= allow.limit;
        }
      }
      return false;
    },
    getItemSeeingEcu(state){
      return state.item_seeing_Ecu
    },
    getItemSeeingTcu(state){
      return state.item_seeing_Tcu
    },
    getFilesOnServer(state){
      return state.file_on_server
    }
  },
  actions: {
    loadCombos({commit, rootState}, dsg) {
      return new Promise(resolve => {
        return get(`combos/get-combo/${dsg}/${rootState.geo.userCountry.alpha2Code}`, null, true)
          .then(({data}) => {
            resolve(true);
            commit('setCombos', data.combos.map(el => ({
              ...el,
              id: `${el.ecu}-${el.dsg}`,
              price: checkPrice(el.price)
            })));
            commit('setComboRules', data.rules
              .filter(el => el.max || el.min)
              .map(el => ({
                min: Number(el.min),
                max: Number(el.max),
                ecu: el.ecu.toString(),
              }))
            );
          });
      });
    },
    loadTCUbyDsgFamily({commit}, dsg) {
      return new Promise(resolve => {
        return get(`vehicles-dsg/tcu/${dsg}`, null, true)
          .then(({data}) => {
            resolve(true);
            commit('setTCUForCustomOrder', processDataDSGTunning(data.data, data.stage_visibility));
          });
      });
    },
    loadServerFiles({commit, state}, payload) {
      return new Promise(resolve => {
        return post(`files/find-files/${payload.type.toUpperCase()}`, {
          hardware_number: payload.hardware_number,
          software_version: payload.software_version,
          tool: payload.tool,
        }, true)
          .then(({data}) => {
            commit('setFilesOnServer', {type: payload.type, ...data});
            commit('setStagevisibility', data.stage_visibility);
              // if (data.stock && data.stock.dsg_family) {
              if (!state.vehicle.dsg_family && data.stock) {
              commit('setVehicle', {
                dsg_family: data.stock.dsg_family,
                dsg: data.stock.dsg_family.split('_')[0]
              });
            }

            if (!state.vehicle.id && data.stock) {
              //is mandatory prior TCU to dsg_family
              commit('setVehicle', {
                dsg_family: data.stock.dsg_family,
                id: data.stock.vehicle_id
              });
            }
            resolve(data);
          });
      });
    },

    async forCustomOrderByVehicleId({commit, state, rootState}, id) {
      let lastDsgUploaded = findDsgFamilyById(state.file_on_server, state.vehicle.id, state.vehicle.dsg_family)
      const {data} = await get(`site/${id}?dsg=${lastDsgUploaded}&alphaCountry=${rootState.geo.userCountry.alpha2Code}`, null, true);
      const ecu = processDataSoftwareTunning(data.message.tuning, data.message.stage_visibility);
      const tcu = processDataDSGTunning(data.message.dsg, data.message.stage_visibility);
      const combos = processCombos(data.message.combos, ecu, tcu).combos;
      commit('setDataForCustomOrder', {
        ecu,
        combos,
        tcu
      });
      commit('setCombos', combos);
    },

    async loadDropdownFilter({commit, state}, filterAttr) {
      commit('setDataForCustomOrder', {
        ecu: [],
        combos: [],
        tcu: [],
      });
      let url = filterModel;
      let key = 'models';
      switch (filterAttr) {
        case 'brands':
          url = 'site/brands';
          key = 'brands';
          break;
        case 'models':
          state.dropdowns_values.generations = [];
          state.dropdowns_values.engines = [];
          state.dropdowns_values.gearboxes = [];
          state.dropdowns_filter.model = null;
          state.dropdowns_filter.generation = null;
          state.dropdowns_filter.engine = null;
          state.vehicle.id = null;
          break;
        case 'generations':
          key = 'generations';
          url = filterGeneration;
          state.engines = [];
          state.dropdowns_values.gearboxes = [];
          state.dropdowns_filter.generation = null;
          state.dropdowns_filter.engine = null;
          state.vehicle.id = null;
          break;
        case 'engines':
          key = 'engines';
          url = filterEngine;
          state.dropdowns_filter.engine = null;
          state.dropdowns_filter.gearbox = null;
          state.dropdowns_values.gearboxes = [];
          state.vehicle.id = null;
          break;
        case 'gearboxes':
          state.dropdowns_filter.gearbox = null;
          state.dropdowns_values.gearboxes = [];
          state.vehicle.id = null;
      }
      const {data} = await post(url, state.dropdowns_filter, false);
      commit('setDropdownValue', {
        key,
        data: data.message
      });
    },
    async loadDataCustomFromFilter({commit, state, rootState}, checkGearbox) {
      const params = {
        country: rootState.geo.userCountry.alpha2Code,
        ...state.dropdowns_filter
      };
      const {data} = await post(site, params, false);
      if (checkGearbox) {
        commit('setDataForCustomOrder', {
          ecu: [],
          combos: [],
          tcu: []
        });
        if (data.message.tuning.dsg.length > 1) {
          data.message.tuning.dsg.map(item => {
            state.dropdowns_values.gearboxes.push({
              text: item.split('_')[0],
              value: item.trim()
            });
          });
        }
      }
      if ((!state.dropdowns_values.gearboxes.length || !checkGearbox)) {
        const ecu = processDataSoftwareTunning(data.message.tuning, data.message.stage_visibility);
        const tcu = processDataDSGTunning(data.message.dsg, data.message.stage_visibility);
        const combos = processCombos(data.message.combos, ecu, tcu).combos;
        const vehicle = data.message.tuning;
        commit('setDataForCustomOrder', {
          ecu,
          combos,
          tcu
        });
        commit('setVehicle', {
          id: vehicle.id,
          brand: vehicle.make,
          model: vehicle.model,
          generation: vehicle.generation,
          engine: vehicle.engine,
          dsg: state.dropdowns_filter.gearbox ? state.dropdowns_filter.gearbox.split('_')[0] : vehicle.dsg[0].split('_')[0],
          dsg_family: state.dropdowns_filter.gearbox ? state.dropdowns_filter.gearbox : vehicle.dsg[0].split('_')[0],
          engine_family: vehicle.engineFamily,
        });
        return true;
      } else {
        return false;
      }
    },
    async loadUncompletedPreOrder({commit, state}) {
      post(`order-software/find-history-vin`, {vin: state.file_details.VIN}, true)
        .then(({data}) => {
          commit('setLastOrder', (data ? data : null));
        });
    },
    loadCompletedPreOrder({commit, state}) {
      if (!state.previous_order.to_upgrade) {
        post(`order-software/find-history-to-upgrade`, {vin: state.file_details.VIN}, true)
          .then(({data}) => {
            if (data.order) {
              const order_date = moment(data.order.createdAt);
              const diff = moment.duration(moment()
                .diff(order_date));
              if (diff.asYears() < 3) {
                commit('setToUpgradeOrder', {
                  id: data.order.id,
                  type: data.order.type,
                  price: data.order.price,
                  created_at: order_date,
                  stage: data.order.stage,
                  time_diff: diff.asYears(),
                  currencyDetails: data.order.currencyDetails
                });
              }
              commit('setCompletedOrder', data.order);
            } else {
              post(`order-software/find-order-completed-vin`, {vin: state.file_details.VIN}, true)
                .then(({data}) => {
                  if (data) {
                    commit('setCompletedOrder', data);
                  }
                });
            }
          });
      }
    },
    async loadPricePolicy({commit, rootState, rootGetters}) {
      return new Promise(resolve => {
        get(`common/price-policy?country_id=${rootState.geo.userCountry.id}&dealer_id=${rootGetters.getUserId}`)
          .then(({data}) => {
            commit('setPricePolicy', {
              discount_after_a_year: data.data.discount_after_a_year,
              minimum_price: data.data.minimum_price,
              only_on_upgrade_fee: data.only_on_upgrade_fee
            });
            resolve();
          });
      });

    },
    clear({commit}) {
      commit('clear');
    },
  }



};

import Vue from 'vue';
import Vuex from 'vuex';
import support from '@/store/support';
import orderSoftware from '@/store/order-software';
import orderSoftwareFile from '@/store/order-software-file';
import {decrypt, encrypt} from '@/helpers/encrypt';

import notification from '@/store/notification';
import geo from '@/store/geo';
import buySale from '@/store/buy-sale';
import priceList from "@/store/price-list";
import counterInformation from "@/store/counter-information";
import paymentGateway from "@/store/payment-gateway";
import subUserPermission from "@/store/sub-user-permission";
import reportCreditPurchaseStore from "@/store/reports/creditPurchaseStore";
import reportCreditRevenueStore from "@/store/reports/creditRevenueStore";
import reportSoftwareDashboardStore from "@/store/reports/softwareDashboardStore";
import reportSoftwarePendingStore from "@/store/reports/softwarePendingStore";
import reportSoftwareAnalyticsStore from "@/store/reports/softwareAnalyticsStore";
import reportUserDashboardStore from "@/store/reports/userDashboardStore";
import reportUserPromotionStore from "@/store/reports/userPromotionStore";
import reportCreditPredictedStore from "@/store/reports/creditPredictedStore";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    support,
    orderSoftware,
    orderSoftwareFile,
    notification,
    geo,
    buySale,
    priceList,
    counterInformation,
    paymentGateway,
    subUserPermission,
    reportCreditPurchaseStore,
    reportCreditPredictedStore,
    reportCreditRevenueStore,
    reportSoftwareDashboardStore,
    reportSoftwarePendingStore,
    reportSoftwareAnalyticsStore,
    reportUserDashboardStore,
    reportUserPromotionStore,
  },
  state: {
    loader: false,
    notificationPending: {
      status: false,
      title: '',
      text: '',
    },
    userName: '',
    socketChannel: '',
    user: null,
    isCompanyEmpty: false,
    showReportsAlert: false,
  },
  getters: {
    getUserId: (state) => {
      return Number((state.user.id));
    },
    getRol: (state) => {
      return state.user.role
    }
  },
  mutations: {
    loaderManager(state, payload) {
      state.loader = payload;
    },
    notificationManager(state, payload) {
      state.notificationPending = payload;
    },
    userNameManager(state, payload) {
      state.userName = payload;
    },
    setUser(state, payload) {
      state.user = payload;
      localStorage.setItem('tvs-user', encrypt(JSON.stringify(payload)));
    },
    setIsCompanyEmpty(state, payload) {
      state.isCompanyEmpty = payload;
    },
    setUserAtt(state, payload) {
      state.user = {...state.user, ...payload}
      localStorage.setItem('tvs-user', encrypt(JSON.stringify(state.user)));
    }
  },
  actions: {
    loadUser(action) {
      try {
        const value = JSON.parse(decrypt(localStorage.getItem('tvs-user')));
        if (value) {
          action.commit('setUser', value);
          action.dispatch('geo/getUserCountry', value.country_id);
        }
      } catch (e) {
      }
    }
  },
});

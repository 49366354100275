import Vue from 'vue';
import Router from 'vue-router';

import {UserRole} from '@/utils/auth.roles';
import AuthGuard from './utils/authGuard';
import {encrypt} from "@/helpers/encrypt";

Vue.use(Router);

const redirectWithEncrypt = (to, from, next) => {
  if (!isNaN(to.params.id)) {
    next({name: to.name, params: {id: encrypt(JSON.stringify({id: to.params.id}))}})
  }
  next()
}
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
  routes: [
    {
      path: '*',
      redirect: '/login',
    },
    {
      name: 'invoice',
      path: '/invoice/:id',
      meta: {
        requiresAuth: false,
        layout: 'blank',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
      },
      component: () => import('./components/Invoice.vue')
    },
    {
      name: 'overview-pdf',
      path: '/overview-pdf/:id/:rol',
      meta: {
        requiresAuth: false,
        layout: 'blank',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
      },
      component: () => import('./components/CreditOverviewPdf.vue')
    },
    {
      name: 'dummy',
      path: '/dummy/:id',
      meta: {
        requiresAuth: false,
        layout: 'blank',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
      },
      component: () => import('./components/Dummy.vue')
    },
    {
      path: '/put-order',
      name: 'put-order',
      component: () => import('./components/PutOrder')
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        requiresAuth: false,
        layout: 'login',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
      },
      component: () => import('./views/login/index.vue'),
    },
    {
      path: '/two-factor',
      name: 'two-factor',
      meta: {
        requiresAuth: false,
        layout: 'auth',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
      },
      component: () => import('./views/two-factor/TwoFactor.vue'),
    },
    {
      path: '/register',
      name: 'register',
      meta: {
        requiresAuth: false,
        layout: 'auth',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
      },
      component: () => import('./views/register/index.vue'),
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      meta: {
        requiresAuth: false,
        layout: 'auth',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
      },
      component: () => import('./views/forgot-password/index.vue'),
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password',
      meta: {
        requiresAuth: false,
        layout: 'auth',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
      },
      component: () => import('./views/reset-password/index.vue'),
    },
    {
      path: '/terms',
      name: 'terms',
      meta: {
        requiresAuth: false,
        layout: 'auth',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
      },
      component: () => import('./views/terms/index.vue'),
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      meta: {
        requiresAuth: false,
        layout: 'auth',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
      },
      component: () => import('./views/privacy-policy/index.vue'),
    },
    {
      path: '/validate-email',
      name: 'validate-email',
      meta: {
        requiresAuth: false,
        layout: 'auth',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
      },
      component: () => import('./views/validate-email/index.vue'),
    },
    {
      path: '/coming-soon',
      name: 'coming-soon',
      meta: {
        requiresAuth: false,
        layout: 'default',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
      },
      component: () => import('./views/comming-soon/index.vue'),
    },
    {
      path: '/error',
      name: 'error',
      meta: {
        requiresAuth: false,
        layout: 'default',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Support, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
      },
      component: () => import('./views/404/404.vue'),
    },
    {
      name: 'close-sessions',
      path: '/close-sessions/:token',
      meta: {
        requiresAuth: false,
        layout: 'blank',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
      },
      component: () => import('./views/close-sessions/CloseSessions.vue')
    },
    {
      name: 'cert',
      path: '/cert/:id/:print?',
      meta: {
        requiresAuth: false,
        layout: 'blank'
      },
      component: () => import('@/components/CertSoftwareTunning.vue')
    },
    {
      name: 'detail-order',
      path: '/detail-order/:number_order',
      meta: {
        requiresAuth: false,
        layout: 'blank',
      },
      component: () => import('@/components/OrderSoftwareCustomer.vue')
    },
    {
      path: '/dashboard',
      meta: {
        requiresAuth: true,
        layout: 'default',
        roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator, UserRole.Support],
      },
      children: [
        {
          path: '/',
          name: 'dashboard',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Calibrator, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Support],
          },
          component: () => import('./views/dashboard/dashboard/index.vue'),
        },
        {
          path: '/information/:company',
          name: 'information',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Calibrator, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Support],
          },
          component: () => import('./views/dashboard/hiddenPage/index.vue'),
        },
        {
          path: 'required-information',
          name: 'required-information',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator, UserRole.Support],
          },
          component: () => import('./views/dashboard/requirement-information/index.vue'),
        },
        {
          path: 'profile',
          name: 'profile',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator, UserRole.Support],
          },
          component: () => import('./views/dashboard/profile/index.vue'),
        },
        {
          path: 'order-software',
          name: 'order-software',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Support],
          },
          component: () => import('./views/dashboard/create-software-order/mainscreen/index.vue'),
        },
        {
          path: 'order-software-file',
          name: 'order-software-file',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
          },
          component: () => import('./views/dashboard/create-software-order-file/CreateOrderByFile.vue'),
        },
        {
          path: 'order-software-detail/:type/:id',
          name: 'order-software-detail',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
          },
          component: () => import('./views/dashboard/create-software-order/options/index.vue'),
        },
        {
          path: 'vehicles',
          name: 'vehicles',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin],
          },
          component: () => import('./views/dashboard/vehicles/index.vue'),
        },
        {
          path: 'vehicles-file-management',
          name: 'vehicles-file-management',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin],
          },
          component: () => import('./views/dashboard/vehicles-file-management/index.vue'),
        },
        {
          path: 'user',
          name: 'user',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
          },
          component: () => import('./views/dashboard/user/index.vue'),
        },
        {
          path: 'newproducts',
          name: 'newproducts',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin],
          },
          component: () => import('./views/dashboard/newproducts/index.vue'),
        },
        {
          path: 'database',
          name: 'database',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin],
          },
          component: () => import('./views/dashboard/database/index.vue'),
        },
        {
          path: 'import-database',
          name: 'import-database',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin],
          },
          component: () => import('@/views/dashboard/vehicles-importer/VehiclesImporter.vue'),
        },
        {
          path: 'vehicles/:id',
          name: 'view-vehicle',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin],
          },
          component: () => import('./views/dashboard/vehicles/views/ViewEdit.vue'),
        },
        {
          path: 'create-vehicle',
          name: 'create-vehicle',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin],
          },
          component: () => import('./views/dashboard/vehicles/views/Create.vue'),
        },
        {
          path: 'vehicles-pricing',
          name: 'vehicles-pricing',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin],
          },
          component: () => import('./views/dashboard/vehicles-pricing/index.vue'),
        },
        {
          path: 'vehicles-options',
          name: 'vehicles-options',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin],
          },
          component: () => import('./views/dashboard/vehicles-options/index.vue'),
        },
        {
          path: 'vehicles-secondary-options',
          name: 'vehicles-secondary-options',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin],
          },
          component: () => import('./views/dashboard/vehicles-secondary-options/index.vue'),
        },
        {
          path: 'vehicles-dsg',
          name: 'vehicles-dsg',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin],
          },
          component: () => import('./views/dashboard/vehicles-dsg/index.vue'),
        },
        {
          path: 'vehicles-dsg-options',
          name: 'vehicles-dsg-options',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin],
          },
          component: () => import('./views/dashboard/vehicles-dsg-options/index.vue'),
        },
        {
          path: 'vehicles-dsg-secondary-options',
          name: 'vehicles-dsg-secondary-options',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin],
          },
          component: () => import('./views/dashboard/vehicles-dsg-secondary-options/index.vue'),
        },
        {
          path: 'vehicles-combos',
          name: 'vehicles-combos',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin],
          },
          component: () => import('./views/dashboard/vehicles-combos/index.vue'),
        },
        {
          path: 'credit-dash-received',
          name: 'credit-dash-received',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
          },
          component: () => import('./views/dashboard/credit-dashboard/purchase/index.vue'),
        },
        {
          path: 'credit-dash-sent',
          name: 'credit-dash-sent',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
          },
          component: () => import('./views/dashboard/credit-dashboard/sale/index.vue'),
        },
        {
          path: 'credit-dashboard',
          name: 'credit-dashboard',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Support, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
          },
          component: () => import('./views/dashboard/credit-dashboard/index.vue'),
        },
        {
          path: 'credit',
          name: 'credit',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer],
          },
          component: () => import('./views/dashboard/credit/index.vue'),
        },
        {
          path: 'credit-account-import',
          name: 'credit-account-import',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin],
          },
          component: () => import('./views/dashboard/credit/compareExcel.vue'),
        },
        {
          path: 'order-credit',
          name: 'order-credit',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
          },
          component: () => import('./views/dashboard/credit/order/index.vue'),
        },
        {
          path: 'credit-history',
          name: 'credit-history',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
          },
          component: () => import('./views/dashboard/credit-history/index.vue'),
        },
        {
          path: 'software-orders/',
          name: 'orders',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator],
          },
          component: () => import('./views/dashboard/dashboard/index.vue'),
        },
        {
          path: 'order-detail/:id',
          name: 'order-detail',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Calibrator, UserRole.Support],
          },
          component: () => import('./views/dashboard/order-detail/index.vue'),
        },
        {
          path: 'support/:id',
          name: 'support-detail',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Support, UserRole.Admin, UserRole.Calibrator, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
          },
          component: () => import('./views/dashboard/support/support-chat/Chat.vue'),
          beforeEnter: redirectWithEncrypt,
        },
        {
          path: 'support',
          name: 'support',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Calibrator, UserRole.Support, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
          },
          component: () => import('./views/dashboard/support/Inbox.vue'),
        },
        {
          path: 'checkout',
          name: 'checkout',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Support],
          },
          component: () => import('./views/dashboard/checkout/index.vue'),
        },
        {
          path: 'checkout-file',
          name: 'checkout-file',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Support],
          },
          component: () => import('./views/dashboard/checkout/CheckOut.vue'),
        },
        {
          path: 'credit-order-admin-view/:id',
          name: 'credit-order-admin-view',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Admin, UserRole.Calibrator],
          },
          component: () => import('./views/dashboard/credit-order-view-admin/index.vue'),
        },
        {
          path: 'credit-price',
          name: 'credit-price',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Distributor, UserRole.Admin],
            layout: 'default',
          },
          component: () => import('./views/dashboard/credit-price/index.vue')
        },
        {
          path: 'credit-order-history',
          name: 'credit-order-history',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
            layout: 'default',
          },
          component: () => import('./views/dashboard/credit-order-history/index.vue')
        },
        {
          path: 'credit-order-detail/:id',
          name: 'credit-order-detail',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Admin],
            layout: 'default',
          },
          component: () => import('./views/dashboard/credit-order-detail/index.vue'),
          // beforeEnter: redirectWithEncrypt,
        },
        {
          path: 'credit-admin-history',
          name: 'credit-admin-history',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Admin],
            layout: 'default',
          },
          component: () => import('./views/dashboard/credit-history-admin/index.vue')
        },
        {
          path: 'profile-admin/:id',
          name: 'profile-admin',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer],
          },
          component: () => import('./views/dashboard/profile-admin/index.vue'),
        },
        {
          path: 'credit-order-view-invoice/:id',
          name: 'credit-order-view-invoice',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
          },
          component: () => import('./views/dashboard/credit-order-detail/index.vue'),
        },
        {
          path: 'vin-logger',
          name: 'vin-logger',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin]
          },
          component: () => import('./views/dashboard/vin-search/index.vue')
        },
        {
          path: 'vin-logger-view/:id',
          name: 'vin-logger-view',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin]
          },
          component: () => import('./views/dashboard/vin-search/view/ViewVin.vue')
        },
        {
          path: 'vin-search-rules',
          name: 'vin-search-rules',
          meta: {
            requiresAuth: true,
            layout: 'default',
            roles: [UserRole.Admin]
          },
          component: () => import('./views/dashboard/vin-search-rules/VinSearchRules.vue')
        },
        {
          path: 'coupon',
          name: 'coupon',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Admin, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer, UserRole.Support],
            layout: 'default',
          },
          component: () => import('./views/dashboard/coupon/index.vue')
        },
        {
          path: 'countries',
          name: 'countries',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Admin],
            layout: 'default'
          },
          component: () => import('./views/dashboard/countries/CountriesList.vue')
        },
        {
          path: 'downloads',
          name: 'downloads',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Admin, UserRole.Calibrator, UserRole.Support, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
            layout: 'default',
          },
          component: () => import('./views/dashboard/general-file/index.vue')
        },
        {
          path: 'feedback',
          name: 'feedback',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Admin, UserRole.Support, UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
            layout: 'default',
          },
          component: () => import('./views/dashboard/feedback/index.vue')
        },
        {
          path: 'news',
          name: 'news',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Admin, UserRole.Dealer, UserRole.SubDealer, UserRole.Distributor],
            layout: 'default',
          },
          component: () => import('./views/dashboard/news/index.vue')
        },
        {
          path: '/faq',
          name: 'faq',
          meta: {
            requiresAuth: false,
            layout: 'default',
            roles: [UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
          },
          component: () => import('./views/faqs/index.vue'),
        },
        {
          path: 'subuser-management',
          name: 'subuser-management',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Distributor, UserRole.Dealer, UserRole.SubDealer],
            layout: 'default',
          },
          component: () => import('@/views/dashboard/user-sub/index.vue')
        },
        {
          path: 'price-policy',
          name: 'price-policy',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Admin],
            layout: 'default',
          },
          component: () => import('@/views/dashboard/price-policy-discount/PricePolicyDiscount.vue')
        },
        {
          path: 'software-pricing',
          name: 'software-pricing',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Admin],
            layout: 'default',
          },
          component: () => import('@/views/dashboard/software-pricing/index.vue')
        },
        {
          path: 'files',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Admin],
            layout: 'default',
          },
          children: [
            {
              path: '/',
              name: 'index-tuned-file',
              meta: {
                requiresAuth: true,
                roles: [UserRole.Admin, UserRole.Calibrator],
                layout: 'default'
              },
              component: () => import('@/views/dashboard/file-server/StockFiles.vue')
            },
            {
              path: 'project/:id',
              name: 'project-tuned-file',
              meta: {
                requiresAuth: true,
                roles: [UserRole.Admin, UserRole.Calibrator],
                layout: 'default'
              },
              component: () => import('@/views/dashboard/file-server/stock-file/StockFileDetail.vue')
            },
            {
              path: 'tuned/:id',
              name: 'view-tuned-file',
              meta: {
                requiresAuth: true,
                roles: [UserRole.Admin, UserRole.Calibrator],
                layout: 'default',
              },
              component: () => import('@/views/dashboard/file-server/tuned-file/TunedFileDetail.vue')
            },
            {
              path: 'orders',
              name: 'file-order-crypt-bot-monitor',
              meta: {
                requiresAuth: true,
                roles: [UserRole.Admin, UserRole.Calibrator],
                layout: 'default',
              },
              component: () => import('@/views/dashboard/file-server/bot-monitor/FilesOrder.vue')
            },
            {
              path: 'pre-order',
              name: 'file-pre-order-crypt-bot-monitor',
              meta: {
                requiresAuth: true,
                roles: [UserRole.Admin],
                layout: 'default',
              },
              component: () => import('@/views/dashboard/file-server/bot-monitor/FilesPreOrder.vue')
            },
          ],
          component: () => import('@/views/dashboard/file-server/FileServer.vue')
        },
        {
          path: 'vehicles-price-config',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Admin],
            layout: 'default',
          },
          children: [
            {
              path: '/',
              name: 'index-vehicles-price-config',
              meta: {
                requiresAuth: true,
                roles: [UserRole.Admin],
                layout: 'default',
              },
              component: () => import('@/views/dashboard/vehicles-price-config/Index.vue')
            },
            {
              path: 'importer',
              name: 'vehicles-importer',
              meta: {
                requiresAuth: true,
                roles: [UserRole.Admin],
                layout: 'default',
              },
              component: () => import('@/views/dashboard/vehicles-price-config/Importer.vue')
            },
          ],
          component: () => import('@/views/dashboard/vehicles-price-config/VehiclesPriceConfig.vue')
        },
        {
          path: 'previous-orders',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Admin],
            layout: 'default',
          },
          children: [
            {
              path: '/',
              name: 'list-previous-orders',
              meta: {
                requiresAuth: true,
                roles: [UserRole.Admin],
                layout: 'default',
              },
              component: () => import('@/views/dashboard/previous-orders/List.vue')
            },
            {
              path: 'import',
              name: 'import-previous-orders',
              meta: {
                requiresAuth: true,
                roles: [UserRole.Admin],
                layout: 'default',
              },
              component: () => import('@/views/dashboard/previous-orders/Import.vue')
            },
          ],
          component: () => import('@/views/dashboard/previous-orders/PreviousOrders.vue')
        },
        {
          path: 'reports',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Admin],
            layout: 'default',
          },
          children: [
            {
              path: '/reports-credit',
              name: 'reports-credit',
              meta: {
                requiresAuth: true,
                roles: [UserRole.Admin],
                layout: 'default',
              },
              component: () => import('@/views/dashboard/reports/credit/index.vue')
            },
            {
              path: 'reports-software',
              name: 'reports-software',
              meta: {
                requiresAuth: true,
                roles: [UserRole.Admin],
                layout: 'default',
              },
              component: () => import('@/views/dashboard/reports/software')
            },
            {
              path: 'reports-user',
              name: 'reports-user',
              meta: {
                requiresAuth: true,
                roles: [UserRole.Admin],
                layout: 'default',
              },
              component: () => import('@/views/dashboard/reports/user')
            },
          ],
          component: () => import('./views/dashboard/reports/Reports.vue')
        },
        {
          path: 'contact',
          name: 'contact',
          meta: {
            requiresAuth: true,
            roles: [UserRole.Admin],
            layout: 'auth',
          },
          component: () => import('@/views/contact/Contact.vue'),
        },
      ],
      component: () => import('./layouts/Dashboard.vue'),
    },
  ],
});
router.beforeEach(AuthGuard);
export default router;

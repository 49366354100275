import {get, post} from '@/services/api';
import {invoiceCanBuySale} from '@/services/endpoint.json';

export default {
  namespaced: true,
  state: {
    loaded: false,
    information: {
      balance: 0,
      canBuy: false,
      canSale: false,
      haveChildren: false,
      haveParent: false,
      parentId: null,
      invoiceInformationP: false,  // el padre del usuario en sesion
      invoiceInformationU: false, // el usuario en sesion
      priceList: false,
      orderSection: null,
      rol: [],
      permissions: [],
      allowedOrderNoBalance: {
        limit: 0,
        status: false
      },
      totalOrderSoftwareToPay: 0,

    },
    subUsers: [],
    file_server_enabled: false,
  },
  mutations: {
    setInformation: (state, payload) => {
      state.information = payload;
    },
    setSubUsers: (state, payload) => {
      state.subUsers = payload;
    },
    setLoaded: (state, payload) => {
      state.loaded = payload;
    },
    setFileServerEnabled: (state, payload) => {
      state.file_server_enabled = payload;
    }
  },
  getters: {
    getInformation: state => {
      return state.information;
    },
    hasFullAccessOrder: state => {
      return state.information.orderSection === 'ECU-COMBO-DSG' || !state.information.orderSection;
    }
  },
  actions: {
    async loadInformation({commit}) {
      get('user/is-file-server-enabled', null, true).then(({data}) => {
        commit('setFileServerEnabled', data.enabled);
      });
      return new Promise(resolve => {
        post(invoiceCanBuySale, null, true)
          .then(response => {
            commit('setInformation', response.data);
            resolve(response.data);
            commit('setLoaded', true);
          });
      });
    },
    loadUserList({commit}) {
      get('user-network')
        .then(({data}) => {
          commit('setSubUsers', data);
        });
    }
  }
};

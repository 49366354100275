<template>
  <span>{{ price }}</span>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {UserRole} from '@/utils/auth.roles';

export default {
  name: 'CurrencyPriceView',
  props: {
    amount: {
      required: true,
      type: Number,
    },
    currencyDetails: {
      default: false,
      type: Object
    },
    ligth: {
      default: false,
      type: Boolean
    },
    /**
     * si se pasa se mostrara obligatoria mente el currerncy details (pasado por props, o del usuario en session)
     */
    noSwitch: {
      default: false,
      type: Boolean
    },
    hideDecimals: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapState('geo', ['userCurrency', 'tvsCurrency', 'showInLocal']),
    ...mapGetters('geo', ['getCurrencyByCountry']),
    currency: function () {
      if (!this.currencyDetails) {
        if (this.showInLocal || this.noSwitch) {
          return this.userCurrency;
        } else {
          return {...this.tvsCurrency, locale: this.userCurrency.locale};
        }
      }

      if (this.$userCan([UserRole.Admin, UserRole.Calibrator, UserRole.Support])) {
        if (this.showInLocal) {
          return {...this.currencyDetails, locale: this.userCurrency.locale};
        } else {
          return {...this.tvsCurrency, locale: this.userCurrency.locale};
        }
      }
      if (!this.showInLocal && !this.noSwitch) {
        return {...this.tvsCurrency, locale: this.userCurrency.locale};
      }
      return {...this.currencyDetails, locale: this.userCurrency.locale};
    },
    price: function () {
      let amount = this.amount * (this.currency.price);
      const formater = new Intl.NumberFormat(this.currency.locale, {
        style: 'currency',
        currency: this.currency.code,
        minimumFractionDigits: this.hideDecimals ? 0 : 2,
        maximumFractionDigits: this.hideDecimals ? 0 : 2,
        currencyDisplay: 'symbol'
      });
      return formater.format(amount);
    }
  },
};
</script>

<style scoped>

</style>

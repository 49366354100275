import {KEYS_LOCALSTORAGE, ORDER_CREDIT_STATUS, ORDER_STATUS, OVERVIEW_CATEGORY, URGENCY_NAMES} from '@/constants';
import {getDataStorage} from '@/helpers/localStorage';
import *  as crypto from "crypto";

export const isStagevisible = (id, data) => {
  if (data) {
    let result = data.find(el => el.id == id);
    if (result) {
      return result.status
    }else{
      return true
    }
  }else{
    return true;
  }
}

export const getDefaultBrowserLanguage = () => {
  const userLang = navigator.language || navigator.userLanguage;
  let currentLanguage = userLang.slice(0, 2);

  if (currentLanguage !== 'en' && currentLanguage !== 'es') {
    currentLanguage = 'en';
  }

  return currentLanguage;
};

export const setLanguageForApp = () => {
  const languageLocalStorage = localStorage.getItem(KEYS_LOCALSTORAGE.LANGUAGE);

  let language = '';

  if (languageLocalStorage) {
    language = languageLocalStorage;
  } else {
    language = getDefaultBrowserLanguage();
    localStorage.setItem(KEYS_LOCALSTORAGE.LANGUAGE, language);
  }

  return language;
};

export const userCan = (types) => {
  if (!Array.isArray(types)) types = [types];
  const userType = JSON.parse(getDataStorage('admin-type'));
  if (types && userType) {
    return types.some(e => userType.includes(e));
  }
  return false;
};


export const filterObjectAndRemoveEmptyValues = (object) => {
  Object.keys(object).forEach(k => {
    if (isNull(object[k]) || isEmpty(object[k])) {
      delete object[k];
    }
  });
  return object;
}


export function serialize(a, q = '') {
  const s = [];
  const add = function (k, v) {
    v = typeof v === 'function' ? v() : v;
    v = v === null ? '' : v === undefined ? '' : v;
    if (typeof v === 'boolean') {
      v = v ? 1 : 0;
    }
    s[s.length] = `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
  };
  const buildParams = function (prefix, obj) {
    let i,
      len,
      key;

    if (prefix) {
      if (Array.isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          buildParams(
            `${prefix}[${typeof obj[i] === 'object' && obj[i] ? i : ''}]`,
            obj[i],
          );
        }
      } else if (String(obj) === '[object Object]') {
        for (key in obj) {
          buildParams(`${prefix}[${key}]`, obj[key]);
        }
      } else {
        add(prefix, obj);
      }
    } else if (Array.isArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        add(obj[i].name, obj[i].value);
      }
    } else {
      for (key in obj) {
        buildParams(key, obj[key]);
      }
    }
    return s;
  };

  return q + buildParams('', a)
    .join('&');
}

export const isUndefined = value => value === undefined;

export const isNull = value => value === null;

export const isBoolean = value => typeof value === 'boolean';

export const isObject = value => value === Object(value);

export const isArray = value => Array.isArray(value);

export const isDate = value => value instanceof Date;

export const isBlob = value =>
  value &&
  typeof value.size === 'number' &&
  typeof value.type === 'string' &&
  typeof value.slice === 'function';

export const isFile = value =>
  isBlob(value) &&
  typeof value.name === 'string' &&
  (typeof value.lastModifiedDate === 'object' ||
    typeof value.lastModified === 'number');

export const isEmpty = value =>
  isUndefined(value)
  || isNull(value)
  || value === '';

export function serializeToDataForm(obj, cfg, fd, pre) {
  cfg = cfg || {};

  cfg.indices = isUndefined(cfg.indices) ? false : cfg.indices;

  cfg.nullsAsUndefineds = isUndefined(cfg.nullsAsUndefineds)
    ? false
    : cfg.nullsAsUndefineds;

  cfg.booleansAsIntegers = isUndefined(cfg.booleansAsIntegers)
    ? true
    : cfg.booleansAsIntegers;

  cfg.allowEmptyArrays = isUndefined(cfg.allowEmptyArrays)
    ? false
    : cfg.allowEmptyArrays;

  fd = fd || new FormData();

  if (isUndefined(obj)) {
    return fd;
  } else if (isBoolean(obj)) {
    if (cfg.booleansAsIntegers) {
      fd.append(pre, obj ? 1 : 0);
    } else {
      fd.append(pre, obj);
    }
  } else if (isArray(obj)) {
    if (obj.length) {
      obj.forEach((value, index) => {
        const key = `${pre}[${cfg.indices ? index : ''}]`;

        serializeToDataForm(value, cfg, fd, key);
      });
    } else if (cfg.allowEmptyArrays) {
      fd.append(`${pre}[]`, '');
    }
  } else if (isDate(obj)) {
    fd.append(pre, obj.toISOString());
  } else if (isObject(obj) && !isFile(obj) && !isBlob(obj)) {
    Object.keys(obj)
      .forEach((prop) => {
        const value = obj[prop];

        if (isArray(value)) {
          while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
            prop = prop.substring(0, prop.length - 2);
          }
        }

        const key = pre ? `${pre}[${prop}]` : prop;

        serializeToDataForm(value, cfg, fd, key);
      });
  } else {
    if (!isNull(obj) && !isUndefined(obj)) {
      fd.append(pre, obj);
    }
  }
  return fd;
}


export function getUrgencyName(v) {
  if (v !== null && v !== '') {
    return URGENCY_NAMES.find(value => value.value == v).text;
  } else {
    return null;
  }
}

export function getOrderStatus(v) {
  if (v !== null && v !== '') {
    const data = ORDER_STATUS().find(value => value.value.toString() === v.toString());
    return (data !== undefined) ? data.text : v
  } else {
    return null;
  }
}

export function getCreditStatus(v) {
  if (v !== null && v !== '') {
    const data = ORDER_CREDIT_STATUS().find(value => value.value.toString() === v.toString());
    return (data !== undefined) ? data.text : v
  } else {
    return null;
  }
}

export function getOverviewCategory(v, arg) {
  if (v !== null && v !== '') {
    const data = OVERVIEW_CATEGORY.find(value => value.value.toString() === v.toString());
    if (data !== undefined) {
      if (!arg.includes('TVS')) {
        return data.text // add resell
      } else {
        return data.text
      }
    } else {
      return v
    }
  } else {
    return null;
  }
}

export function generateOnlyRandomString(length) {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result.toUpperCase();
}

export function checksum(str, algorithm = 'md5', encoding = 'hex') {
  return crypto
    .createHash(algorithm || 'md5')
    .update(str, 'utf8')
    .digest(encoding)
}

export function getPercentageIncrease(numA, numB) {
  if (numA < 0) {
    numA = numA * -1
  }
  if (numB < 0) {
    numB = numB * -1
  }
  return numA > 0 ? Number((((numB / numA) - 1) * 100).toFixed(0)) : numB > 0 ? 100 : 0
}

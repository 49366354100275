export default {
  namespaced: true,
  state: {
    notifications: []
  },
  mutations: {
    add(state, payload) {
      state.notifications.push(payload);
    }
  },
  actions: {
    add({ commit }, notification) {
      commit('add', notification);
    }
  }
};

<template>
  <aside class="d-print-none"
         :class="['main-sidebar', 'col-12', 'col-md-3', 'col-lg-2', 'px-0', sidebarVisible ? 'open' : '', isMobile?'overflow':'' ]">
    <div class="main-navbar">
      <nav class="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
        <a class="navbar-brand w-100 mr-0 logo_mobile" href="#">
          <img id="main-logo" alt="TVS"
               class=""
               src="@/assets/images/logo.svg"
               style="width: 100%; height: auto;color: black !important;height: 80px;margin: auto;padding-bottom: 20px;">
        </a>
        <a class="toggle-sidebar d-sm-inline d-md-none d-lg-none" @click="handleToggleSidebar()">
          <i class="material-icons">&#xE5C4;</i>
        </a>
      </nav>
    </div>

    <div class="nav-wrapper">
      <div v-for="(nav, navIdx) in items" :key="navIdx">
        <b-nav v-if="typeof nav.items !== 'undefined' && nav.items.length" class="nav--no-borders flex-column">
          <li v-for="(item, navItemIdx) in nav.items" :key="navItemIdx" class="nav-item dropdown">
            <b-link v-b-toggle="`snc-${navIdx}-${navItemIdx}`"
                    :class="['nav-link', item.items && item.items.length ? 'dropdown-toggle' : '']"
                    :to="item.to"
                    @click.native="handleToggleSidebar"
                    exact>
              <div v-if="item.htmlBefore" class="item-icon-wrapper" v-html="item.htmlBefore"/>
              <span v-if="item.title">{{ item.title }}</span>
              <span v-if="item.count" style="position: absolute; right: 10px;">
                <b-badge v-if="item.title ==='Orders' && newCustomOrders > 0" pill variant="danger">
                  {{ newCustomOrders + newOrders }}
                </b-badge>
                <b-badge v-if="item.title ==='User Management' && newUsers > 0" pill variant="danger">
                  {{ newUsers }}
                </b-badge>
                <b-badge v-if="item.title ==='Tickets' && newTickets > 0" pill variant="danger">
                  {{ newTickets }}
                </b-badge>
                <b-badge v-if="item.title ==='New products' && newCustomProduct > 0" pill variant="danger">
                  {{ newCustomProduct }}
                </b-badge>
              </span>
              <div v-if="item.htmlAfter" class="item-icon-wrapper" v-html="item.htmlAfter"/>
            </b-link>

            <b-collapse v-if="item.items && item.items.length" :id="`snc-${navIdx}-${navItemIdx}`"
                        accordion="sidebar-items-accordion" class="dropdown-menu dropdown-menu-small">
              <b-dropdown-item v-for="(subItem, subItemIdx) in item.items" :key="subItemIdx"
                               v-b-toggle="`snc-${navIdx}-${navItemIdx}`"
                               :href="subItem.href"
                               :to="subItem.to" @click.native="handleToggleSidebar">
                {{ subItem.title }}
              </b-dropdown-item>
            </b-collapse>
          </li>
        </b-nav>
      </div>
    </div>
  </aside>
</template>

<script>
import {getDataStorage} from '@/helpers/localStorage';
import {userCan} from "@/utils";
import {UserRole} from "@/utils/auth.roles";
import {mapState} from "vuex";

export default {
  name: 'main-sidebar',
  props: {
    hideLogoText: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      required: true,
      default: [],
    },
  },
  data() {
    return {
      isMobile: false,
      userID: '',
      newCustomOrders: 0,
      newOrders: 0,
      newTickets: 0,
      newUsers: 0,
      newCustomProduct: 0,
      sidebarVisible: false,
    };
  },
  computed: {
    ...mapState('counterInformation', ['data']),
    counterMenuInformation: function () {
      return this.$store.state.counterInformation.data
    }
  },
  mounted() {
    this.isMobile = (/Mobi/.test(navigator.userAgent))
    console.log('Is mobile ', this.isMobile)
  },
  async created() {
    this.$eventBus.$on('toggle-sidebar', this.handleToggleSidebar);
    this.userID = await getDataStorage('admin-id');
    if (userCan(UserRole.Admin)) {
      this.getRealtimeData();
      this.getCounterData().then()
    }
  },
  methods: {
    handleToggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
    },
    getRealtimeData() {
      this.sockets.subscribe('notify', (tempData) => {
        if (tempData.type === 'newCustomOrder') {
          this.newCustomOrders = this.newCustomOrders + 1;
        }
        if (tempData.type === 'newCustomProduct') {
          this.newCustomProduct = this.newCustomProduct + 1;
        }
        if (tempData.type === 'newUser') {
          this.newUsers = this.newUsers + 1;
        }
        if (tempData.type === 'newOrderSoftware') {
          this.newOrders = this.newOrders + 1;
        }
      });

      this.sockets.subscribe('newTicket', () => {
        this.newTickets = this.newTickets + 1;
      });
    },

    async getCounterData() {
      this.newOrders = this.counterMenuInformation.totalNewOrder
      this.newCustomOrders = this.counterMenuInformation.totalNewCustomOrder
      this.newUsers = this.counterMenuInformation.totalNewUser
      this.newTickets = this.counterMenuInformation.totalNewTicket
      this.newCustomProduct = this.counterMenuInformation.totalNewProduct
    }
  },
};
</script>

<style lang="scss">
.main-sidebar {
  z-index: 1030;

  .item-icon-wrapper {
    display: inline-block;
  }

  .dropdown-menu {
    display: block;
  }
}
.overflow{
  overflow-y: scroll;
}

@media (min-width: 992px){
  .main-sidebar{
    overflow-y: scroll;
  }
}
@media (min-width: 768px){
  .main-sidebar{
    overflow-y: scroll;
  }
}
</style>

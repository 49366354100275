import {get, post} from '@/services/api';

export default {
  namespaced: true,
  state: {
    priceListInformation: [],
    userId: null,
    priceListByUser: []
  },
  mutations: {
    setInformationPriceList: (state, payload) => {
      state.priceListInformation = payload;
    },
    setInformationPriceListByUser: (state, payload) => {
      state.priceListByUser = payload;
    },
  },
  getters: {
    getInformationPriceList: state => {
      return state.priceListInformation;
    },
    getInformationPriceListByUser: state => {
      return state.priceListByUser;
    }
  },
  actions: {
    async loadInformationPriceList({commit}) {
      return new Promise(resolve => {
        get('credit-price-config/price', null, true)
          .then(({data}) => {
            if (data) {
              const temp = data.priceList.sort((a, b) => a.price - b.price);
              commit('setInformationPriceList', temp);
              resolve(data)
            } else {
              resolve(null)
            }
          });
      });
    },

    async loadInformationPriceListByUser({commit, state}) {
      return new Promise(resolve => {
        get('credit-price-config/index/' + state.userId, null, true)
          .then(({data}) => {
            commit('setInformationPriceListByUser', data);
            resolve(data)
          });
      });
    },
  }
};

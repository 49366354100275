import Vue from "vue";
import moment from "moment";
import {getCreditStatus, getOrderStatus, getOverviewCategory, getUrgencyName} from "@/utils";

Vue.filter('localeDate', function (value) {
  if (moment(value)
    .isValid()) {
    const localDate = moment(moment(value)
      .utc())
      .local();
    const localeFormatDate = moment.localeData()
      .longDateFormat('L');
    const localeFormat = moment.localeData()
      .longDateFormat('LTS');
    return localDate.format(`${localeFormatDate} ${localeFormat}`);
  }
  return value;
});
Vue.filter('orderStatus', function (value) {
  return getOrderStatus(value)
});

Vue.filter('orderType', function (value) {
  return (value === 'Stock') ? 'Drivability' : (value === 'original_file') ? 'ORIG File' : value
});

Vue.filter('stage', function (value) {
  if (value)
    return value.replace(/\.1/g, '+')
      .replace('Stock', 'Drivability')
      .replace('original_file', 'ORIG File')
  return value;
});

Vue.filter('stageType', function (value, type) {
  if (!value)
    return value;

  if (!type.includes('+')) {
    const formatted = value.replace(/\.1/g, '+')
      .replace('Stock', 'Drivability')
      .replace('original_file', 'ORIG File')

    return `${type.replace('DSG', 'TCU')}: ${formatted}`
  }
  return value.split('-').map((el, index) => {
    const type = index === 0 ? 'ECU:' : 'TCU:';
    return type + ' ' + el.replace(/\.1/g, '+')
      .replace('Stock', 'Drivability')
      .replace('original_file', 'ORIG File');
  }).join(' / ');

})

Vue.filter('orderTypeExtended', function (value) {
  return value.toLowerCase().replace('stock', ' Drivability')
    .replace('original_file', ' ORIG File')
    .replace(/\.1/g, '+')
});

Vue.filter('creditStatus', function (value) {
  return getCreditStatus(value)
});
Vue.filter('urgency', function (value) {
  return getUrgencyName(value)
});
Vue.filter('overviewCategory', function (value, arg) {
  return getOverviewCategory(value, arg)
});
Vue.filter('dsgFamily', function (value) {
  if (value !== null && value !== "" && value !== undefined) {
    const text = value.split('_');
    return text[0]
  } else
    return value
});
Vue.filter('euro', function (value) {
  const formater = new Intl.NumberFormat('sfb', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });
  return formater.format(value)
    .replace('€', '€ ');
});
Vue.filter('number', function (value) {
  const formater = new Intl.NumberFormat('nl-NL', {
    minimumFractionDigits: 2
  });
  return formater.format(value);
});

Vue.filter('roundNumber', function (value) {
  const formater = new Intl.NumberFormat('nl-NL', {
    minimumFractionDigits: 0
  });
  return formater.format(value);
});
Vue.filter('credits', function (value) {
  const formater = new Intl.NumberFormat('sfb', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
  });
  return formater.format(value)
    .replace('€', 'Ⓒ ');
});
Vue.filter('year', function (value) {
  if (moment(value)
    .isValid()) {
    const localDate = moment(moment(value)
      .utc())
      .local();
    return localDate.format(`Y`)
      .slice(-2);
  }
  return value;
});
Vue.filter('localDateShort', function (value) {
  if (!value)
    return '--';
  if (moment(value).isValid()) {
    const localDate = moment(moment(value)
      .utc())
      .local();
    const localeFormatDate = moment.localeData()
      .longDateFormat('L');
    return localDate.format(`${localeFormatDate}`);
  } else {
    return value;
  }

});

Vue.filter('software_hardware', function (value) {
  return value === 'engine_software' ? 'Engine software' : 'Hardware modification'
});


Vue.filter('showDsg', function (value) {
  if (value.auto_found !== undefined){
    return  value.auto_found && value.brand ? value.dsg: ""
  }
  return value.dsg
});

Vue.filter('hideZeroValue', function (value) {
  return value > 0 ? value : ""
});

import {isStagevisible} from "@/utils";
import {checkPrice} from "@/views/dashboard/create-software-order/processDataSoftwareTunning";

const originalFile = "Original file for replacement of hardware during repairs. Free of charge for TVS hardware, price on request for non TVS hardware"
const stage1Info = "TVS Stage 1 DSG software is a low budget solution which only includes raising necessary torque limiters and is especially suitable for really mild tuned vehicles.";
const stage2Info = "TVS Stage 2 DSG software consists of various necessary software modifications for tuned vehicles improving your vehicle’s performance during launching, acceleration and up- and downshifting!."
const stage2PInfo = "TVS Stage 2+ DSG software is the best priced all-in-one package for your DSG transmission! It does include all drivability & life extender features, necessary performance modifications for tuned vehicles and additional options as well!"
const stage3Info = "TVS Stage 3 DSG software is characterized as the strongest DSG software available on the market and especially suitable for high performance applications. In addition perfect drivability, durability and reliability will maintain."
const stage4Info = "TVS Stage 4 DSG is custom made software for aftermarket DSG race clutches. We recommend purchasing this together with our in-house developed TVS DSG racing clutches."
const stageStock = "Stock (non-tuned) vehicles will strongly benefit from a TVS Drivability upgrade which truly transforms the drivability of a vehicle and even massively increases the drivetrain lifespan due reduced flywheel, clutch and gearbox wear!"

function addSubOptionOriginalFile() {
  const arraySubOptions = [];
  const extra_data = {
    "title": "INVOICE NUMBER",
    "inputs": [
      {
        "label": "Add invoice number",
        "type": "text",
        "field": "invoice_n",
        "maxlength": 10,
        "minlength": 5
      }
    ],
    "on_result": {
      "description": "{optionSelected.description} / Invoice Nº {result.invoice_n}"
    }
  }
  const arrayOptions = ["Not installed", "Mechatronic", "Gearbox", "Other"]

  arrayOptions.forEach((item, index) => {
    arraySubOptions.push({
      new_price: item === 'Not installed' ? 195 : 0,
      created_at: null,
      description: item,
      engine: null,
      enginefamily: null,
      explanation: null,
      extradata: item === 'Not installed' ? null : extra_data,
      id: index + 1,
      option_id: null,
      status: 1,
      type: "hardware_modification",
      updated_at: null,
    })
  })
  return arraySubOptions
}

const checkToSetOnRequest = value => {
  if (!!value) {
    return value
  }
  return 'On Request'
}

function addStockOriginalFile(vehicleId, dsg) {
  return {
    vehicleId: vehicleId,
    type: 'dsg',
    dsg: dsg,
    id: 'original_file',
    level: 'Original File',
    data: originalFile,
    torque: 'Repairs',
    price: 'On request',
    options: [],
    hardwareOptions: [
      {
        add_new_one: 0,
        checked: false,
        choices: null,
        created_at: "",
        default_option: null,
        description: null,
        feature_tcu: null,
        id: 'original_file',
        name: "TVS hardware installed",
        new_price: null,
        option_id: null,
        option_tcu: null,
        options: addSubOptionOriginalFile(),
        recomended: false,
        required: true,
        type: "hardware_modification",
        updated_at: null
      }
    ],
    nameCombo: []
  };
}

export const processDataDSGTunning = (item, stage_visibility = null) => {
  const arrayData = [];
  const tcuVisibility = stage_visibility ? stage_visibility.tcu : null
  if (item) {
    if (isStagevisible('original_file', tcuVisibility)) {
      arrayData.push(addStockOriginalFile(item.vehicle.id, item.DSG))
    }
    if (item.stockTorque && isStagevisible('Stock', tcuVisibility)) {
      arrayData.push({
        vehicleId: item.vehicle.id,
        type: 'dsg',
        dsg: item.DSG,
        id: 'Stock',
        level: 'DRIVABILITY',
        data: stageStock,
        torque: item.stockTorque,
        price: checkPrice(item.stockPrice),
        options: item.stockDsgSoftware ? item.stockDsgSoftware.map(el => ({
          ...el,
          choices: el.choices ? JSON.parse(el.choices) : null
        })) : [],
        hardwareOptions: [],
        nameCombo: []
      })
    }
    if (item.stageOneTorque !== '' && isStagevisible('1', tcuVisibility)) {
      arrayData.push({
        vehicleId: item.vehicle.id,
        type: 'dsg',
        dsg: item.DSG,
        id: '1',
        level: 'STAGE ONE',
        data: stage1Info,
        torque: item.stageOneTorque,
        price: checkPrice(item.stageOnePrice),
        options: item.stageOneDsgSoftware ? item.stageOneDsgSoftware.map(el => ({
          ...el,
          choices: el.choices ? JSON.parse(el.choices) : null
        })) : [],
        hardwareOptions: [],
        nameCombo: ['ecu1Dsg1'],
        img: require('@/assets/images/stages/1.svg')
      })
    }
    if (item.stageTwoTorque !== '' && isStagevisible('2', tcuVisibility)) {
      arrayData.push({
        vehicleId: item.vehicle.id,
        type: 'dsg',
        dsg: item.DSG,
        id: '2',
        data: stage2Info,
        level: 'STAGE TWO',
        torque: item.stageTwoTorque,
        price: checkPrice(item.stageTwoPrice),
        options: item.stageTwoDsgSoftware ? item.stageTwoDsgSoftware.map(el => ({
          ...el,
          choices: el.choices ? JSON.parse(el.choices) : null
        })) : [],
        hardwareOptions: [],
        nameCombo: [],
        img: require('@/assets/images/stages/2.svg')
      })
    }
    if (item.stageTwoPlusTorque !== '' && isStagevisible('2.1', tcuVisibility)) {
      arrayData.push({
        vehicleId: item.vehicle.id,
        type: 'dsg',
        dsg: item.DSG,
        id: '2.1',
        data: stage2PInfo,
        level: 'STAGE TWO +',
        torque: item.stageTwoPlusTorque,
        price: checkPrice(item.stageTwoPlusPrice),
        options: item.stageTwoPlusDsgSoftware ? item.stageTwoPlusDsgSoftware.map(el => ({
          ...el,
          choices: el.choices ? JSON.parse(el.choices) : null
        })) : [],
        hardwareOptions: item.stageTwoPlusDsgHardware ? item.stageTwoPlusDsgHardware.map(el => ({
          ...el,
          choices: el.choices ? JSON.parse(el.choices) : null
        })) : [],
        nameCombo: ['ecu22pDsg2p'],
        img: require('@/assets/images/stages/2P.svg')
      })
    }
    if (item.stageThreeTorque !== '' && isStagevisible('3', tcuVisibility)) {
      arrayData.push({
        vehicleId: item.vehicle.id,
        type: 'dsg',
        dsg: item.DSG,
        id: '3',
        data: stage3Info,
        level: 'STAGE THREE',
        torque: item.stageThreeTorque,
        price: checkPrice(item.stageThreePrice),
        options: item.stageThreeDsgSoftware ? item.stageThreeDsgSoftware.map(el => ({
          ...el,
          choices: el.choices ? JSON.parse(el.choices) : null
        })) : [],
        hardwareOptions: item.stageThreeDsgHardware ? item.stageThreeDsgHardware.map(el => ({
          ...el,
          choices: el.choices ? JSON.parse(el.choices) : null
        })) : [],
        nameCombo: ['ecu22pDsg3'],
        img: require('@/assets/images/stages/3.svg')
      })
    }
    if (!!item.stageFourTorque && isStagevisible('4', tcuVisibility)) {
      arrayData.push({
        vehicleId: item.vehicle.id,
        type: 'dsg',
        dsg: item.DSG,
        id: '4',
        data: stage4Info,
        level: 'STAGE FOUR',
        torque: item.stageFourTorque,
        price: checkPrice(item.stageFourPrice),
        options: item.stageFourDsgSoftware ? item.stageFourDsgSoftware.map(el => ({
          ...el,
          choices: el.choices ? JSON.parse(el.choices) : null
        })) : [],
        hardwareOptions: item.stageFourDsgHardware ? item.stageFourDsgHardware.map(el => ({
          ...el,
          choices: el.choices ? JSON.parse(el.choices) : null
        })) : [],
        nameCombo: ['ecu4Dsg34', 'ecu3Dsg34'],
        img: require('@/assets/images/stages/4.svg')
      })
    }
  }
  return (arrayData);
};

